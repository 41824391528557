
export class Config {

    static AppEnv = import.meta.env.VITE_APP_ENV as "dev" | "beta" | "prod";

    static landingPage = "https://gozen.io/testimonial";

    static maxAllowedSizeIn = {
        mb: 3072, // 3mb
        bytes: 3145728 // 3mb in bytes
    };

    static misc = {
        placeHolderLogo: "https://asserts.dev.testimonial.gozen.io/misc/dummy-logo.png",
        placeHolderImage: "https://asserts.dev.testimonial.gozen.io/misc/image-placeholder.png"
    };

    static cdn() {

        if (this.AppEnv === "dev") return "https://video.dev.testimonial.gozen.io/";
        else if (this.AppEnv === "beta") return "https://video.beta.testimonial.gozen.io/";
        else return "https://video.testimonial.gozen.io/";

    };

    static AllowedOrigins() {

        if (this.AppEnv === "dev") return "http://localhost:3000";
        else if (this.AppEnv === "beta") return "https://dev.app.testimonial.gozen.io";
        else return "https://app.testimonial.gozen.io";

    }

    static Api() {

        if (this.AppEnv === "dev") return "http://localhost:8080";
        else if (this.AppEnv === "beta") return "https://dev.api.testimonial.gozen.io";
        else return "https://api.testimonial.gozen.io";

    };

    static FrontendUrl() {

        if (this.AppEnv === "dev") return "http://localhost:3000";
        else if (this.AppEnv === "beta") return "https://dev.app.testimonial.gozen.io";
        else return "https://app.testimonial.gozen.io";

    }

}