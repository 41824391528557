import { useCommonStore, useFormStore } from "@store";
import { cn } from "@utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { PurifyHTML } from "@utils/purifyHTML";
import { Button } from "@components/button";
import { useFormDesign, useIsPreview } from "@hooks";
import { MdOutlineContentCopy } from "react-icons/md";
import { Config } from "@config";


type Props = {
    show?: boolean,
    winnerSegmentId: string,
};

export function ResultPopup({ show, winnerSegmentId }: Props) {

    const isPreview = useIsPreview();

    const activeRewardSegmentId = useCommonStore((store) => store.activeRewardSegmentId);

    const { segments, logo } = useFormStore((store) => ({
        segments: store.reward.segments,
        logo: store.design.logo,
    }));

    const design = useFormDesign();

    const winnerSegment = segments.filter(coupon => {

        if (isPreview && activeRewardSegmentId && activeRewardSegmentId === coupon.id) {
            return coupon;
        }

        if (isPreview === false && coupon.id === winnerSegmentId) return coupon;

        return null;

    })[0] ?? null;

    const imgRef = useRef<HTMLImageElement>(null);

    const coupon = winnerSegment?.code ?? "COUPON";
    const title = winnerSegment?.popup?.title ? winnerSegment?.popup?.title : "Coagulations!";
    const message = winnerSegment?.popup.message ? winnerSegment?.popup.message : "Enter the coupon code to get 30% of your purchase.";
    const btnText = winnerSegment?.popup?.cta?.text.length ? winnerSegment?.popup?.cta?.text : "Redeem";

    const onClick = () => {

        if (!winnerSegment?.popup?.cta?.url) return;

        localStorage.clear();

        window.open(winnerSegment?.popup?.cta?.url);

    };

    return createPortal(
        <AnimatePresence initial={false}>

            {show ?
                <motion.div
                    className={cn(`
                    absolute top-0 left-0 w-screen h-screen overflow-hidden flex items-center justify-center
                    transition-colors duration-100 z-[1000] 
                `)}
                    initial={{
                        backdropFilter: "blur(0px)"
                    }}
                    animate={{
                        backdropFilter: "blur(10px)"
                    }}
                    exit={{
                        // transition: {
                        //     delay: 0.2,
                        //     duration: 0.1,
                        //     ease: "easeOut"
                        // }
                    }}
                // transition={{
                //     duration: 0.1
                // }}
                >

                    <motion.div
                        id="popup-main"
                        className={cn(`z-[100] bg-white shadow-md rounded-md p-4`)}
                        style={{
                            width: isPreview ? "70%" : "40%",
                            fontFamily: design.fontFamily,
                            background: design.cardColor
                        }}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0,
                        }}
                        transition={{
                            delay: 0.1,
                            duration: 0.3,
                        }}
                        dir={design.enableRTL ? "rtl" : "ltr"}
                    >

                        <div className="space-y-2">

                            <div className="flex items-center justify-start">

                                <img
                                    ref={imgRef}
                                    src={logo ?? Config.misc.placeHolderLogo}
                                    alt="dummy image"
                                    className="w-[50px] h-[50px]"
                                    onError={() => { if (imgRef.current) imgRef.current.src = Config.misc.placeHolderLogo; }}
                                />

                            </div>

                            <h1
                                className="text-2xl font-bold tracking-wide"
                                dangerouslySetInnerHTML={{ __html: PurifyHTML(title) }}
                                style={{
                                    color: design.fontColor
                                }}
                            />

                            <p
                                className="text-base font-medium text-gray-700 tracking-wide"
                                dangerouslySetInnerHTML={{ __html: PurifyHTML(message) }}
                                style={{
                                    color: design.fontColor
                                }}
                            />

                            {coupon ?
                                <div className={cn(`
                                    border border-gray-300 rounded-lg p-2 text-black  
                                    flex items-center justify-center space-x-3
                                `)}>

                                    <p
                                        className={cn("text-xl text-center font-bold", {
                                            "ml-2": design.enableRTL
                                        })}
                                        style={{
                                            color: design.fontColor
                                        }}
                                    >
                                        {coupon}
                                    </p>

                                    <div
                                        className={cn(`
                                            bg-gray-200 p-1 rounded-md inline-block hover:bg-gray-300 cursor-pointer
                                            transition-all duration-300 ease-in-out
                                        `)}
                                    >

                                        <MdOutlineContentCopy className="w-5 h-5" />

                                    </div>

                                </div> : null
                            }

                        </div>

                        {winnerSegment?.popup?.cta?.url ?
                            <Button
                                disabled={isPreview}
                                className="mt-4"
                                text={btnText}
                                onClick={() => onClick()}
                            /> : null
                        }

                    </motion.div>

                </motion.div> : null
            }

        </AnimatePresence>,
        document.getElementById("popup") as HTMLDivElement
    );

}