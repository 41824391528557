import { Config } from "@config";


export const RequestInterceptor = (config: any) => {

    const api = Config.Api();

    config.url = api + config.url;

    config.withCredentials = true;

    return config;

};
