import { Rating } from "@components/rating";
import { QueryKeys, useGetQueryData } from "@hooks";
import { cn } from "@utils/cn";
import { TextTestimonial } from "../types/text";
import { AudioTestimonial } from "../types/audio";
import { VideoTestimonial } from "../types/video";
import { useFormStore } from "@store";
import { CustomerDetails } from "./customerDetails";
import { TestimonialSource } from "@components/testimonialSources";

type Props = {
    isEmbed: boolean;
};

export function TestimonialLayout({ isEmbed }: Props) {

    const rtl = useFormStore((store) => store.design.enableRTL);

    const query = useGetQueryData<Api.TestimonialClient.get>([QueryKeys.testimonial.get]);

    const submittedAt = query?.testimonial?.submittedAt ?? new Date().toISOString();
    const source = query?.testimonial?.source ?? "gozenTestimonial";
    const rating = query?.testimonial?.testimonial?.rating ?? 0;
    const type = query?.testimonial?.type ?? "text";

    if (!query) return <></>;

    return (
        <div
            className={cn(`
                sm:w-[75%] md:w-[40%] h-auto bg-white rounded-lg shadow-lg 
                border p-4 space-y-4 py-5 my-10 z-[100]
            `, {
                "my-0 shadow-none": isEmbed,
            })}
            dir={rtl ? "rtl" : "ltr"}
        >

            <div className="flex items-center justify-between border-b border-gray-300 ">

                <CustomerDetails />

                <div className="flex items-center justify-center px-2">

                    <TestimonialSource source={source} sourceLink="" />

                </div>


            </div>

            {rating ?
                <Rating
                    rated={rating}
                    readonly={true}
                /> : null
            }

            {type === "text" ? <TextTestimonial /> : null}

            {type === "audio" ? <AudioTestimonial /> : null}

            {type === "video" ? <VideoTestimonial /> : null}

            <p className="text-xs font-medium text-gray-600 mt-3">
                {new Date(submittedAt).toLocaleString("EN", { dateStyle: "medium" })}
            </p>

        </div>
    );
}