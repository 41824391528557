
type Props = {
    size?: number;
};

export function Cursor({ size }: Props) {
    return (
        <svg
            width={size ?? "100"}
            height={size ?? "100"}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M36.5359 67L1.89488 7C0.355286 4.33334 2.27979 1 5.35899 1L74.641 1C77.7202 1 79.6447 4.33334 78.1051 7L43.4641 67C41.9245 69.6667 38.0755 69.6667 36.5359 67Z"
                fill="#FEAD14"
                stroke="black"
                strokeWidth="3"
            />
        </svg>
    );
}