import { useCallback, useEffect, useRef, useState } from "react";
import { MediaRecodingState } from "../video/main.video";


type Props = {
    recordingState: MediaRecodingState | null,
    height: number,
    width: number,
    mediaRecorder: MediaRecorder;
};

interface CustomCanvasRenderingContext2D extends CanvasRenderingContext2D {
    roundRect: (
        x: number,
        y: number,
        w: number,
        h: number,
        radius: number
    ) => void;
}

const calculateBarData = (
    frequencyData: Uint8Array,
    width: number,
    barWidth: number,
    gap: number
): number[] => {

    let units = width / (barWidth + gap);
    let step = Math.floor(frequencyData.length / units);

    if (units > frequencyData.length) {
        units = frequencyData.length;
        step = 1;
    }

    const data: number[] = [];

    for (let i = 0; i < units; i++) {

        let sum = 0;

        for (let j = 0; j < step && i * step + j < frequencyData.length; j++) {
            sum += frequencyData[i * step + j];
        }

        data.push(sum / step);
    }

    return data;
};

const draw = (
    data: number[],
    canvas: HTMLCanvasElement,
    barWidth: number,
    gap: number,
    backgroundColor: string,
    barColor: string
): void => {

    const amp = canvas.height / 2;

    const ctx = canvas.getContext("2d") as CustomCanvasRenderingContext2D;

    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (backgroundColor !== "transparent") {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    data.forEach((dp, i) => {

        ctx.fillStyle = barColor;

        const x = i * (barWidth + gap);
        const y = amp - dp / 2;
        const w = barWidth;
        const h = dp || 1;

        ctx.beginPath();

        if (ctx.roundRect) {
            // making sure roundRect is supported by the browser
            ctx.roundRect(x, y, w, h, 50);
            ctx.fill();
        } else {
            // fallback for browsers that do not support roundRect
            ctx.fillRect(x, y, w, h);
        }

    });

};

export const LiveAudioVisualizer: React.FC<Props> = ({ height, width, mediaRecorder }) => {

    const barWidth = 6;
    const gap = 4;
    const backgroundColor = "transparent";
    const barColor = "#000";
    const fftSize = 1024;
    const maxDecibels = -10;
    const minDecibels = -90;
    const smoothingTimeConstant = 0.6;

    const [context] = useState(() => new AudioContext());
    const [analyser, setAnalyser] = useState<AnalyserNode>();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {

        if (!mediaRecorder || !mediaRecorder?.stream) return;

        const analyserNode = context.createAnalyser();
        setAnalyser(analyserNode);

        analyserNode.fftSize = fftSize;
        analyserNode.minDecibels = minDecibels;
        analyserNode.maxDecibels = maxDecibels;
        analyserNode.smoothingTimeConstant = smoothingTimeConstant;

        const source = context.createMediaStreamSource(mediaRecorder.stream);
        source.connect(analyserNode);

    }, [(mediaRecorder?.stream ?? null)]);

    useEffect(() => {

        if (analyser && mediaRecorder.state === "recording") report();

    }, [analyser, (mediaRecorder?.state ?? null)]);

    const report = useCallback(() => {

        if (!analyser) return;

        const data = new Uint8Array(analyser?.frequencyBinCount);

        if (mediaRecorder.state === "recording") {

            analyser?.getByteFrequencyData(data);
            processFrequencyData(data);
            requestAnimationFrame(report);

        } else if (mediaRecorder.state === "paused") {

            processFrequencyData(data);

        } else if (

            mediaRecorder.state === "inactive" &&
            context.state !== "closed"

        ) context.close();


    }, [analyser, context.state]);

    const processFrequencyData = (data: Uint8Array): void => {

        if (!canvasRef.current) return;

        const dataPoints = calculateBarData(data, canvasRef.current.width, barWidth, gap);

        draw(
            dataPoints,
            canvasRef.current,
            barWidth,
            gap,
            backgroundColor,
            barColor
        );
    };

    return (
        <canvas
            id="audio-visualizer"
            ref={canvasRef}
            width={width}
            height={height}
        />
    );
};
