import { useLocation } from "react-router-dom";


export function useIsPreview() {

    const location = useLocation();

    const paths = location.pathname.split("/");

    return paths.includes("preview");

}