import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../keys";

const State: Common.State = {
    currentStep: "welcome",
    previewType: "laptop",
    previewTestimonialType: "video",
    activeRewardSegmentId: "",
    spinWheelSpun: false,
    submitted: false,
};

export const useCommonStore = create<Common.Type>()(devtools(persist(immer((set => ({
    ...State,
    setCurrentStep: (step) => set((state) => { state.currentStep = step; },
        false,
        {
            type: "common/setCurrentStep",
        }
    ),
    setPreviewType: (type) => set((state) => { state.previewType = type; },
        false,
        {
            type: "common/setPreviewType"
        }
    ),
    setPreviewTestimonialType: (type) => set((state) => { state.previewTestimonialType = type; },
        false,
        {
            type: "common/setPreviewTestimonialType"
        }
    ),
    setActiveRewardSegmentId: (id) => set((state) => { state.activeRewardSegmentId = id; },
        false,
        {
            type: "common/setActiveRewardSegmentId"
        }
    ),
    setSpinWheelSpun: (val) => set((state) => { state.spinWheelSpun = val; },
        false,
        {
            type: "common/setSpinWheelSpun"
        }
    ),
    setSubmitted: (val) => set((state) => {

        state.submitted = val;

    },
        false,
        {
            type: "common/setSubmitted"
        }
    ),
    clear: () => set((state) => {

        state.activeRewardSegmentId = "";
        state.currentStep = "welcome";
        state.previewTestimonialType = "text";
        state.previewType = "laptop";
        state.submitted = false;
        state.spinWheelSpun = false;

    },
        false,
        "useCommonStore/clear"
    )
}))), {
    name: StoreKeys.common
}), {
    name: StoreKeys.common,
    enabled: import.meta.env.DEV
}));