import { Rating } from "@components/rating";
import { cn } from "@utils/cn";

type Props = {
    testimonials: (Partial<WolType.Type>[]),
    settings?: {
        isCarousel: boolean,
        isGrid: boolean,
        renderAsCard: boolean;
    };
};

export function ProfileGridWidget({ testimonials, settings }: Props) {

    const isCarousel = settings?.isCarousel && testimonials.length;

    return (
        <div className={cn("w-full h-full p-6 bg-white flex items-center justify-center", {
            "flex items-center justify-between w-[50%]": isCarousel || testimonials.length === 1,
            "grid grid-cols-3 gap-6": settings?.isGrid ?? false
        })}>

            {testimonials.map(({ customerDetails, testimonial }, idx) => (
                <div
                    key={idx}
                    className={cn("flex flex-col justify-center items-center p-2", {
                        "border rounded-lg shadow-lg px-4 py-4": settings?.renderAsCard
                    })}
                >

                    <div>

                        <Rating
                            rated={4}
                            readonly
                            iconProps={{
                                className: "w-5 h-5"
                            }}
                        />

                    </div>


                    <div className={cn("space-x-1 text-center mt-2 cursor-default", {
                    })}>

                        <span className="font-bold text-xl opacity-50">“</span>

                        <span
                            className="text-sm font-medium tracking-wide text-gray-700"
                            dangerouslySetInnerHTML={{
                                __html: testimonial?.text?.content ?? ""
                            }}
                        />

                        <span className="font-bold text-xl opacity-50 inline-block">”</span>

                    </div>

                    <div className={cn("text-center mt-2 cursor-default flex items-center space-x-2", {})}>

                        <img
                            src={customerDetails?.profilePic?.answer ?? ""}
                            className="rounded-full w-10 h-10 border border-gray-300 shadow-md"
                        />

                        <div className="text-left">

                            <p className="text-sm font-medium tracking-wide">
                                {customerDetails?.name?.answer ?? ""}
                            </p>


                            <p className="text-sm font-normal tracking-wide text-gray-600">
                                {customerDetails?.role?.answer ?? ""} of {customerDetails?.company?.answer ?? ""}
                            </p>

                        </div>


                    </div>

                </div>
            ))
            }

            {/* {isCarousel ?
                <button
                    className={cn(`
                        bg-gray-100 hover:bg-gray-200 rounded-full p-2 
                        transition-all duration-200 ease-in-out outline-none
                    `)}
                    onClick={() => {

                        if (currIdx + 1 < (testimonials?.length ?? 0)) setCurrIdx((prev) => prev + 1);

                    }}
                >

                    <FaChevronRight className="w-4 h-4 text-gray-600" />

                </button> : null
            } */}

        </div >
    );
}