import { Button } from "@components/button";
import { Logo } from "@components/icons";
import { Input } from "@components/input";
import { Text } from "@components/text";
import { Config } from "@config";
import { cn } from "@utils/cn";
import { useSearchParams } from "react-router-dom";


export function BrandingCard() {

    const [searchParams] = useSearchParams();

    const name = searchParams.get("name") ?? "";
    const email = searchParams.get("email") ?? "";

    const onClick = () => {

        if (!email) return;

        const url = new URL(`${Config.FrontendUrl()}/signup`);

        url.searchParams.set("email", email as string);

        if (name) url.searchParams.set("name", name as string);

        window.open(url.toString(), "_blank");

    };

    return (
        <div className={cn(`
            sm:w-[90%] md:w-[40%] 
            border border-gray-300 shadow-lg bg-white p-6 rounded-lg mt-10 mb-10 space-y-5 z-[10]
            xs:w-[92%] xs:shadow-md xs:px-4 xs:py-6
        `)}>

            <div className="space-y-1.3">

                <div className="flex items-center justify-between">

                    <Text className="text-xl font-medium tracking-wide break-words">

                        {window.innerWidth < 600 ?
                            "Collect your Testimonials" :
                            "You just send a testimonial with GoZen"
                        }

                    </Text>

                    <Logo
                        width={window.innerWidth ? 30 : 40}
                        height={window.innerWidth ? 30 : 40}
                    />

                </div>

                <Text className="text-sm xs:text-xs font-normal text-gray-700 mt-2">
                    Sign up for free to start collecting testimonials too.
                </Text>

            </div>

            <div className={cn(`
                flex items-center w-full mt-4 
                xs:flex-col
            `)}>

                <Input
                    type="email"
                    mainClassName="w-[60%] xs:w-full"
                    placeholder="riched@pp.com"
                    value={email}
                    className="rounded-r-none xs:rounded-md py-3"
                    onChange={() => { }}
                />

                <Button
                    className={cn(`
                        w-[40%] mt-0 text-sm font-medium tracking-wide py-[10px] rounded-l-none
                        xs:w-full xs:mt-2 xs:rounded-md  
                    `)}
                    onClick={() => onClick()}
                >
                    Sign up for free
                </Button>

            </div>

        </div>
    );
}