
export const QueryKeys = {
    form: {
        get: "get-form"
    },
    analytics: {
        update: "update"
    },
    testimonial: {
        get: "get-testimonial",
        submit: "submit-testimonial",
        getUploadUrl: "get-upload-url",
        getMetaData: "get-metadata",
        getWallOfLove: "get-wall-of-love",
        uploadFile: "upload-file",
        caseStudy: {
            get: "get-case-study"
        }
    }
};