import { useEffect, useMemo, useRef, useState } from "react";
import { Cursor } from "./cursor";
import { getSize } from "./getSize";
import { useIsPreview } from "@hooks";
import { CenterCircle } from "./centerCircle";
import { cn } from "@utils/cn";
import { useCommonStore, useFormStore } from "@store";
import { ResultPopup } from "../popup";

type Props = {
    showPopup?: boolean;
};

export function SpinWheel({ showPopup }: Props) {

    const { previewType, wheelSpun } = useCommonStore((store) => ({
        previewType: store.previewType,
        wheelSpun: store.spinWheelSpun
    }));

    const reward = useFormStore((store) => store.reward);

    const isPreview = useIsPreview();

    const { divSize, arrow } = getSize({
        isPreview,
        isPreviewTypeMobile: previewType === "mobile"
    });

    const audio = useMemo(() => new Audio("/tick.mp3"), []);

    const [completed, setCompleted] = useState(false);
    const [winnerSegmentId, setWinnerSegmentId] = useState("");

    const wheelRef = useRef<any>(null);
    const wheelSpinning = useRef(false);

    const isMobileView = window.innerWidth < 600;

    const { color1, color2, fontColor, segments } = reward;

    useEffect(() => onLoad(), []);

    useEffect(() => {

        if (wheelSpun && isPreview === false) {
            onSpinWheel();
        }

    }, [wheelSpun]);

    useEffect(() => {

        if (isPreview) onLoad();

    }, [JSON.stringify(reward)]);

    const onSpinWheel = () => {

        if (wheelSpinning.current === false) {

            const winningSegment = 3;

            const segmentAngle = Math.floor(360 / segments.length);

            const winningSegmentAngle = (winningSegment * segmentAngle) - 10;

            wheelRef.current.animation.stopAngle = winningSegmentAngle;

            wheelRef.current.startAnimation();

            wheelSpinning.current = true;

        }

    };

    const onLoad = () => {

        if (wheelRef.current) wheelRef.current = null;

        const parsedSegments = segments.map((item, idx) => {

            const isEven = (idx + 1) % 2 === 0;

            return {
                fillStyle: isEven ? color1 : color2,
                text: item.title,
                strokeStyle: '#000',
                textFillStyle: fontColor,
                lineWidth: 3
            };

        });

        // @ts-ignore
        const wheel = new Winwheel({
            numSegments: parsedSegments.length,
            textFontSize: isMobileView ? 12 : 18,
            lineWidth: 2,
            rotationAngle: 0,
            segments: [...parsedSegments],
            animation: {
                type: "spinToStop",
                duration: 15,
                spins: 10,
                callbackFinished: onFinish,
                callbackSound: onPlaySound,
                soundTrigger: "pin",
            },
            pins: {
                number: isMobileView ? 20 : 24,
                fillStyle: "#eee",
                responsive: true,
                margin: isMobileView ? 6 : 10,
                outerRadius: isMobileView ? 3 : 4,
                strokeStyle: "#000",
                lineWidth: 1
            },
        });

        wheelRef.current = wheel;

    };

    const onFinish = (indicatedSegment: any) => {

        console.log(indicatedSegment);

        const endAngle = indicatedSegment?.endAngle;

        const segmentAngle = Math.floor(360 / segments.length);

        const segmentNumber = Math.floor(endAngle / segmentAngle);

        wheelRef.current.segments[segmentNumber].fillStyle = "#FEAD14";

        wheelRef.current.draw();

        useCommonStore.getState().setSpinWheelSpun(true);

        setCompleted(true);

        setWinnerSegmentId(segments[segmentNumber].id);

        /* 
            TODO

            - decide winning segment based on the probability 

        */

    };

    const onPlaySound = () => {
        audio.pause();
        audio.currentTime = 0;
        audio.play();
    };

    // const onResetWheel = () => {
    //     wheelRef.current.stopAnimation(false);
    //     wheelRef.current.rotationAngle = 0;
    //     wheelRef.current.draw();
    //     wheelSpinning.current = false;
    // };

    return (
        <div className={cn(`
            md:w-[80%] md:h-[80%] flex items-center justify-center
            sm:w-[90%] sm:h-[90%] 
        `)}>

            <div
                className="relative w-full h-full flex items-center justify-center"
            >

                {isPreview === false ?
                    <div
                        className={cn(`absolute top-[-15px] transition-all duration-300 ease-out`)}
                        style={{
                            top: isMobileView ? "-10px" : "-30px",
                            left: `${arrow.position}px`
                        }}
                    >

                        <Cursor size={arrow.size} />

                    </div> : null
                }

                <CenterCircle />

                <div className="bg-black rounded-full">

                    <canvas
                        id="canvas"
                        width={divSize}
                        height={divSize}
                        className={cn("p-4", {
                            "p-2": isMobileView
                        })}
                    >

                        <p>
                            Sorry, your browser doesn't support canvas. Please try another.
                        </p>

                    </canvas>

                </div>

            </div>

            <ResultPopup
                show={(isPreview && showPopup) || (isPreview === false && completed)}
                winnerSegmentId={isPreview ? "" : winnerSegmentId}
            />

        </div>
    );
}

export default SpinWheel;