import { useFormStore, useTestimonialStore } from "@store";
import { LongTextField } from "./longText";
import { Input } from "@components/input";
import { useIsPreview } from "@hooks";

type Props = {
    error: { [key: string]: string; };
};

export function CustomFields({ error }: Props) {

    const isPreview = useIsPreview();

    const collectableFields = useFormStore((store) => store.customDetails.customFields.filter(field => !field.hidden));

    return (
        <div className="space-y-2">

            {collectableFields.map((field, idx) => {
                return (
                    <div
                        key={idx}
                        className=""
                    >

                        {field.type === "email" ?
                            <Input
                                label={field.label}
                                type="email"
                                placeholder={field.placeHolder ?? "love@gozen.io"}
                                required={field.required}
                                disabled={isPreview}
                                error={error[field.id] ?? ""}
                                onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                                    answer: e.target.value,
                                    id: field.id,
                                    type: field.type,
                                    varType: "string"
                                })}
                            /> : null
                        }

                        {field.type === "longText" ?
                            <LongTextField
                                error={error[field.id] ?? ""}
                                field={field}
                            /> : null
                        }

                        {field.type === "text" ?
                            <Input
                                label={field.label}
                                type="text"
                                disabled={isPreview}
                                required={field.required}
                                placeholder={field.placeHolder ?? `Enter your ${field.label}`}
                                error={error[field.id] ?? ""}
                                onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                                    answer: e.target.value,
                                    id: field.id,
                                    type: field.type,
                                    varType: "string"
                                })}
                            /> : null
                        }

                        {field.type === "url" ?
                            <Input
                                label={field.label}
                                type="url"
                                disabled={isPreview}
                                required={field.required}
                                placeholder={field.placeHolder ?? "Enter yor url"}
                                error={error[field.id] ?? ""}
                                onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                                    id: field.id,
                                    answer: e.target.value,
                                    type: field.type,
                                    varType: "string"
                                })}
                            /> : null
                        }

                    </div>
                );
            })}

        </div>
    );
}