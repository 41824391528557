import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../keys";

const State: UtilStore.State = {
    files: {},
    imageAttachments: {}
};

export const useUtilStore = create<UtilStore.Type>()(devtools(immer((set => ({
    ...State,
    setFile: (key, file) => set((state) => { state.files[key] = file; },
        false,
        "utils/setFile"
    ),
    setImageAttachments: (key, attachments) => set((state) => { state.imageAttachments[key] = attachments; },
        false,
        "utils/setImageAttachments"
    ),
    removeAttachment: (id) => set((state) => { delete state.imageAttachments[id]; },
        false,
        "utils/removeAttachment"
    )
}))), {
    name: StoreKeys.utils,
    enabled: import.meta.env.DEV
}));