import { cn } from "@utils/cn";
import { ReactNode } from "react";
import { CgSpinner } from 'react-icons/cg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean,
    children: ReactNode;
}


export function SecondaryButton({ children, className, loading = false, disabled, ...props }: Props) {
    return (
        <button
            {...props}
            className={cn(`
                w-full bg-white-600 rounded-md px-5 py-2 font-medium text-black text-base mt-3 opacity-100 
                flex items-center justify-center bg-gray-100 border border-gray-300
                transition-all duration-100 ease-in-out 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-gray-300 
            `, {
                "hover:ring-2 hover:ring-offset-1 hover:ring-gray-300 hover:bg-gray-200": !loading && !disabled,
                "cursor-not-allowed": loading || disabled
            }, className)}
        >

            {loading ? <CgSpinner className="w-6 h-6 animate-spin text-primary absolute" /> : null}

            <span className={cn("visible flex items-center justify-center tracking-wide", { "invisible": loading })}>
                {children}
            </span>

        </button>
    );
}