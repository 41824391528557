import { ParseAxiosError } from "@lib/axios";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";


type Props = {
    formId: string;
};

export function useFormApiClient({ formId }: Props) {

    const get = useQuery({
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 1,
        queryKey: [QueryKeys.form.get],
        queryFn: async () => {
            try {

                return (await axios.get<Api.FormClient.get>("/api/v1/form/render", {
                    params: {
                        id: formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
    });

    return { get };

}