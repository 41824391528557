import { useFormDesign, useIsPreview } from "@hooks";
import { useCommonStore, useFormStore, useTestimonialStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";
import { useEffect, useState } from "react";


export function ResponsePageDescription() {

    const isPreview = useIsPreview();

    const design = useFormDesign();

    const { response, fontFamily } = useFormStore((store) => ({
        response: store.response,
        fontFamily: store.design.fontFamily
    }));
    const previewTestimonialType = useCommonStore((store) => store.previewTestimonialType);
    const type = useTestimonialStore((store) => store.type);

    const { audio, text, video } = response;

    const [description, setDescription] = useState("");
    const [previewDescription, setPreviewDescription] = useState("");

    useEffect(() => {

        if (type === "video") setDescription(video.description ?? "");
        else if (type === "audio") setDescription(audio.description ?? "");
        else setDescription(text.description ?? "");

    }, []);

    useEffect(() => {

        if (isPreview) {

            if (previewTestimonialType === "video") setPreviewDescription(video.description ?? "");
            else if (previewTestimonialType === "audio") setPreviewDescription(audio.description ?? "");
            else setPreviewDescription(text.description ?? "");

        }

    }, [audio.description, text.description, video.description, previewDescription]);

    return (
        <p
            className="text-base font-medium text-gray-700 tracking-wide"
            dangerouslySetInnerHTML={{ __html: PurifyHTML(isPreview ? previewDescription : description) }}
            style={{
                fontFamily,
                color: design.fontColor
            }}
        />
    );
}