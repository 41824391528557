import { cn } from "@utils/cn";
import { getSize } from "./getSize";
import { useIsPreview } from "@hooks";
import { useCommonStore } from "@store";


export function CenterCircle() {

    const isPreview = useIsPreview();

    const previewType = useCommonStore((store) => store.previewType);

    const { centerCircle, } = getSize({
        isPreview,
        isPreviewTypeMobile: previewType === "mobile"
    });

    const { inner, left, outer, top } = centerCircle;

    return (
        <div
            className={cn(`absolute top-[${top}px] left-[${left}px]`)}
        >

            <div
                className={cn(`
                    border-[3px] border-amber-800 bg-[#FEAD14] rounded-full
                    flex items-center justify-center
                `)}
                style={{
                    width: outer,
                    height: outer
                }}
            >

                <div
                    className={cn(`rounded-full border-[3px] border-yellow-700 bg-[#f7c15d]`)}
                    style={{
                        width: inner,
                        height: inner
                    }}
                />

            </div>

        </div>
    );
}