import { useFormStore } from "@store";
import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import ReactHlsPlayer from 'react-hls-player';
import { GrPause, GrPlay } from "react-icons/gr";

export function IntroVideo() {

    const introVideo = useFormStore((store) => store.welcome.introVideo);

    const [playing, setPlaying] = useState(false);

    const playerRef = useRef<any>(null);

    useEffect(() => {

        if (introVideo && introVideo?.autoPlay) {
            playerRef.current.play();
        }

    }, [(introVideo?.autoPlay ?? false)]);

    const onClick = () => {

        if (playing) playerRef.current.pause();
        else playerRef.current.play();

        setPlaying(!playing);

    };

    if (!introVideo?.url) return <></>;

    return (
        <div className="border border-transparent h-[280px] w-full rounded-lg group">

            <ReactHlsPlayer
                playerRef={playerRef}
                src={introVideo?.url ?? ""}
                autoPlay={false}
                controls={false}
                width="100%"
                height="auto"
                className="rounded-lg h-full w-full max-h-full max-w-full relative"
                onClick={() => onClick()}
            />

            <div
                className={cn(`
                    rounded-full p-4 bg-black text-white absolute top-[44%] left-[48%] cursor-pointer 
                    transition-all duration-300 ease-in-out hidden group-hover:block
                `)}
                onClick={() => onClick()}
            >

                <span className="flex items-center justify-center">

                    {playing ?
                        <GrPause className="w-10 h-10 text-white" /> :
                        <GrPlay className="w-10 h-10 text-white" />
                    }

                </span>

            </div>

        </div>
    );
}