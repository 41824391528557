import { Config } from "@config";
import { cn } from "@utils/cn";
import { useRef, useEffect, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { FaPause, FaPlay } from "react-icons/fa6";

type Props = {
    url: string;
};

export function AudioTestimonial({ url }: Props) {

    const playerRef = useRef<any>(null);

    const [playing, setPlaying] = useState(false);

    const audioUrl = Config.cdn() + url;

    useEffect(() => {

        if (playerRef.current) {
            playerRef.current.play();
        }

    }, []);

    const onPlay = () => {

        if (playing) {
            playerRef.current?.pause();
            setPlaying(false);
        } else {
            playerRef.current?.play();
            setPlaying(true);
        }

    };

    return (
        <div className={cn(`
            flex flex-col items-center justify-center border border-gray-200 
            rounded-lg mt-4 bg-gray-100 
        `)}>

            <div className="boxContainer w-auto my-6 space-x-2">

                <div className={cn("box", { "box1": playing })} />

                <div className={cn("box", { "box2": playing })} />

                <div className={cn("box", { "box3": playing })} />

                <div className={cn("box", { "box4": playing })} />

                <div className={cn("box", { "box5": playing })} />

            </div>

            <ReactHlsPlayer
                playerRef={playerRef}
                src={audioUrl}
                autoPlay
                controls={false}
                onPlay={() => setPlaying(false)}
                onPause={() => setPlaying(true)}
                className="w-full m-auto rounded-md outline-none shadow-sm"
            />

            <div
                className={cn(`
                    absolute bottom-2 right-5 p-1.5 hover:bg-gray-300/40 
                    transition-all duration-300 cursor-pointer rounded-md
                `)}
                onClick={() => onPlay()}
            >

                {playing ?
                    <FaPause className="w-6 h-6 text-black" /> :
                    <FaPlay className="w-6 h-6 text-black" />
                }

            </div>

        </div>
    );
}