import { Button, } from "@components/button";
import { useAnalyticsApiClient, useCustomLabels, useFormDesign, useIsPreview } from "@hooks";
import { useCommonStore, useFormStore } from "@store";
import { cn } from "@utils/cn";
import { PurifyHTML } from "@utils/purifyHTML";
import { Fragment } from "react";
import { GoDeviceCameraVideo } from "react-icons/go";
import { GoPencil } from "react-icons/go";
import { useParams } from "react-router-dom";
import { useTestimonialStore } from "@store";
import { IntroVideo } from "./introVideo";


export function Welcome() {

    const params = useParams();
    const labels = useCustomLabels();
    const isPreview = useIsPreview();

    const { title, description, allowedTestimonialTypes, alignment, introVideo } = useFormStore((store) => ({
        title: store.welcome.title,
        description: store.welcome.description,
        allowedTestimonialTypes: store.welcome.allowedTestimonialTypes,
        alignment: store.design.alignment,
        introVideo: store.welcome.introVideo
    }));

    const design = useFormDesign();

    const formId = params.id as string;

    const allowVideo = allowedTestimonialTypes.includes("video");
    const allowText = allowedTestimonialTypes.includes("text");
    const allowAudio = allowedTestimonialTypes.includes("audio");

    const { update } = useAnalyticsApiClient();

    const onClick = (type: Testimonial.State["type"]) => {

        useTestimonialStore.getState().setType(type);

        useCommonStore.getState().setCurrentStep("collect");

        update.mutate({ id: formId, event: "started" });

        const startedAt = useTestimonialStore.getState().startedAt;

        if (!startedAt) useTestimonialStore.getState().setStartedAt(new Date().toISOString());

        if (type === "text") {

            useTestimonialStore.getState().setTextTestimonial({
                content: "",
                imageAttachments: []
            });

        } else if (type === "video") {

            useTestimonialStore.getState().setVideoTestimonial({
                duration: 0,
                format: "",
                size: 0
            });

        } else if (type === "audio") {

            useTestimonialStore.getState().setAudioTestimonial({
                duration: 0,
                format: "",
                size: 0,
                imageAttachments: []
            });

        }

    };

    console.log({ introVideo });

    return (
        <Fragment>

            <div className={cn("space-y-2", {
                "text-center": alignment === "center",
                "text-left": alignment === "left",
                "text-right": alignment === "right" || design.enableRTL
            })}>

                <h1
                    className="text-2xl font-bold tracking-wide cursor-default"
                    dangerouslySetInnerHTML={{ __html: PurifyHTML(title) }}
                    style={{
                        fontFamily: design.fontFamily,
                        color: design.fontColor
                    }}
                />

                <p
                    className="text-base font-medium text-gray-700 tracking-wide cursor-default"
                    dangerouslySetInnerHTML={{ __html: PurifyHTML(description) }}
                    style={{
                        fontFamily: design.fontFamily,
                        color: design.fontColor
                    }}
                />

            </div>

            {introVideo && introVideo.url ? <IntroVideo /> : null}

            <div className={cn("space-y-3 mt-4")}>

                {allowVideo ?
                    <Button
                        disabled={isPreview}
                        className="font-medium text-sm py-3"
                        onClick={() => onClick("video")}
                        style={{
                            backgroundColor: design.primaryColor,
                            fontFamily: design.fontFamily
                        }}
                    >

                        <GoDeviceCameraVideo className="w-5 h-5" />

                        <p className="mx-2"> {labels.recordVideoBtn} </p>

                    </Button> : null
                }


                {allowText || allowAudio ?
                    <div
                        id="welcome-cta-section"
                        className={cn(`
                            flex items-center
                            md:flex-row md:space-y-0  
                        `, {
                            "md:space-x-2": design.enableRTL === false,
                            "space-x-2": design.enableRTL === false && isPreview,
                            "xs:flex-col xs:space-x-0 xs:space-y-2": isPreview === false,
                        })}
                    >

                        {allowText ?
                            <Button
                                disabled={isPreview}
                                variant="secondary"
                                className={cn("font-medium text-sm py-3 mt-0", {
                                    "ml-2": design.enableRTL && allowText && allowAudio
                                })}
                                onClick={() => onClick("text")}
                            >

                                <GoPencil className="w-5 h-5" />

                                <p className="mx-2"> {labels.writeTestimonialBtn} </p>

                            </Button> : null
                        }

                        {allowAudio ?
                            <Button
                                disabled={isPreview}
                                variant="secondary"
                                className={cn("font-medium text-sm py-3 mt-0")}
                                onClick={() => onClick("audio")}
                            >

                                <GoPencil className="w-5 h-5" />

                                <p className="mx-2"> {labels.recordAudioTestimonialBtn} </p>

                            </Button> : null
                        }

                    </div> : null
                }

            </div>

        </Fragment >
    );
};

export default Welcome;