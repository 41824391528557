import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> { }

export function XIcon(props: Props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 48 48"
        >
            <path fill="#212121" fill-rule="evenodd" d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28	c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z" clip-rule="evenodd" />
            <path fill="#fff" d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z" />
            <polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34" />
            <polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14" />
        </svg>
    );
}