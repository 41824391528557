import { useFormStore } from "@store";
import { useMemo } from "react";

export function useFormDesign() {

    const design = useFormStore((store) => store.design);

    const newDesign = useMemo<Form.State["design"]>(() => ({
        fontFamily: design.fontFamily ? `"${design.fontFamily}", sans-serif` : '"Inter", sans-serif;',
        logo: design.logo,
        alignment: design.alignment,
        background: {
            color: design.background.color,
        },
        buttonFontColor: design.buttonFontColor ? design.buttonFontColor : "#fff",
        cardColor: design.cardColor,
        fontColor: design.fontColor,
        primaryColor: design.primaryColor ? design.primaryColor : "#3D5AF1",
        enableRTL: design.enableRTL
    }), [JSON.stringify(design)]);

    return { ...newDesign };

}