import { Button, SecondaryButton } from "@components/button";
import { cn } from "@utils/cn";
import { forwardRef, useRef, useState } from "react";
import { FiVideo } from "react-icons/fi";
import { MdOutlineRefresh } from "react-icons/md";
import { GrPause, GrPlay } from "react-icons/gr";
import { useTestimonialStore, useCommonStore, useFormStore } from "@store";
import { FileUploadingGIF } from "@components/fileUploadingGif";
import { useTestimonialApiClient } from "@hooks";


type Props = {
    replayVideoRef: React.MutableRefObject<HTMLVideoElement | null>,
    blobChunks: React.MutableRefObject<BlobPart[]>,
    onRestart: () => void,
    onPlay: (val: boolean) => void;
};

export const ReplayAudio = forwardRef<HTMLAudioElement, Props>(({ replayVideoRef, blobChunks, onRestart, onPlay }, ref) => {

    const formId = useFormStore((store) => store.id);

    const divRef = useRef<HTMLDivElement>(null);

    const [play, setPlay] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const rawBlob = new Blob(blobChunks.current, { type: "audio/webm" });

    const { getUploadUrl, uploadFile } = useTestimonialApiClient({});

    const handlePlay = () => {
        setPlay(!play);
        onPlay(!play);
    };

    const onSubmit = async () => {
        try {

            setUploading(true);

            const data = await getUploadUrl.mutateAsync({
                formId,
                responseId: useTestimonialStore.getState().id,
                source: "testimonial",
                files: [{
                    contentLength: rawBlob.size,
                    key: "audio",
                    type: "audio/webm"
                }]
            });

            await uploadFile({
                url: data.urls[0].url,
                contentType: data.urls[0].contentType,
                fields: data.urls[0].fields,
                key: data.urls[0].key,
                file: rawBlob
            }, (progressVal) => setProgress(progressVal));

            const ele = document.getElementById("testimonial-preview") as HTMLVideoElement;

            if (!ele) return;

            const params: Testimonial.State["testimonial"]["audio"] = {
                duration: ele.duration,
                size: Math.floor((rawBlob.size / 1024) / 1024), // converting bytes to mb
                format: "audio/webm",
                imageAttachments: [],
            };

            useTestimonialStore.getState().setAudioTestimonial(params);

            // await submit.mutateAsync({
            //     formId,
            //     id: testimonialId,
            //     source: "testimonial",
            //     event: {
            //         type: "audio",
            //         testimonial: {
            //             audio: { ...params },
            //             rating
            //         },
            //         landedAt: useTestimonialStore.getState().landedAt,
            //         startedAt: useTestimonialStore.getState().startedAt,
            //         metaData: useTestimonialStore.getState().metaData,
            //     }
            // });

            setUploading(false);

            useCommonStore.getState().setCurrentStep("customDetails");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="relative">

            <div
                ref={divRef}
                className="group w-full h-[300px] border-2 rounded-lg flex items-center justify-center"
            >

                <FileUploadingGIF
                    show={uploading}
                    progress={progress}
                />

                <audio
                    id="testimonial-preview"
                    autoPlay
                    controls={false}
                    ref={ref}
                    onClick={() => {
                        setPlay(!play);
                        onPlay(!play);
                    }}
                />

                <video
                    autoPlay
                    controls={false}
                    ref={replayVideoRef}
                    className={cn(`w-full h-full object-cover rounded-xl`, {
                        "hidden": uploading,
                        "block": uploading === false
                    })}
                    onClick={() => handlePlay()}
                    onEnded={() => setPlay(false)}
                />

                <div
                    className={cn(`
                        rounded-full p-4 text-white absolute top-[30%] left-[45%]
                        cursor-pointer hidden group-hover:block
                        transition-all duration-300 ease-in-out 
                    `)}
                    onClick={() => handlePlay()}
                >

                    <span className="flex items-center justify-center">

                        {play ?
                            <GrPause className="w-10 h-10 text-white" /> :
                            <GrPlay className="w-10 h-10 text-white" />
                        }

                    </span>

                </div>

            </div>

            <SecondaryButton
                onClick={() => onRestart()}
                className="text-sm font-medium tracking-wide space-x-2"
            >

                <MdOutlineRefresh className="w-5 h-5 mx-2" />

                <p> Restart </p>

            </SecondaryButton>

            <Button
                disabled={uploading}
                loading={uploading}
                onClick={() => onSubmit()}
                className="text-sm font-medium tracking-wide"
            >

                <FiVideo className="w-5  h-5 text-white mx-2" />

                <p> Submit </p>

            </Button>


        </div>
    );
});