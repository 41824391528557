import { Input } from "@components/input";
import { UploadPicture } from "@components/uploadPicture";
import { useIsPreview } from "@hooks";
import { useFormStore, useTestimonialStore, useUtilStore } from "@store";

type Props = {
    error: { [key: string]: string; };
};

export function CommonFields({ error }: Props) {

    const isPreview = useIsPreview();

    const fields = useFormStore((store) => store.customDetails.fields);

    return (
        <div className="space-y-2 pt-4 pb-1">

            {fields.name.collect ?
                <Input
                    label="Name"
                    type="text"
                    placeholder="Richy Rich"
                    disabled={isPreview}
                    required={fields.name.required}
                    error={error?.name ?? ""}
                    onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                        id: "name",
                        answer: e.target.value,
                        type: "text",
                        varType: "string"
                    })}
                /> : null
            }

            {fields.email.collect ?
                <Input
                    label="Email Address"
                    type="email"
                    placeholder="love@testimonial.io"
                    disabled={isPreview}
                    required={fields.email.required}
                    error={error?.email ?? ""}
                    onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                        id: "email",
                        answer: e.target.value,
                        type: "email",
                        varType: "string"
                    })}
                /> : null
            }

            {fields.photo.collect ?
                <UploadPicture
                    label="Your photo"
                    required={fields.photo.required}
                    onChange={(file) => useUtilStore.getState().setFile("photo", file)}
                /> : null
            }

            {fields.job.collect ?
                <Input
                    label="Job Title"
                    type="text"
                    placeholder="Product Manager"
                    disabled={isPreview}
                    required={fields.job.required}
                    error={error?.job ?? ""}
                    onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                        id: "job",
                        answer: e.target.value,
                        type: "text",
                        varType: "string"
                    })}
                /> : null
            }

            {fields.website.collect ?
                <Input
                    label="Your website url"
                    type="url"
                    placeholder="love@testimonial.io"
                    disabled={isPreview}
                    required={fields.website.required}
                    error={error?.website ?? ""}
                    onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                        id: "website",
                        answer: e.target.value,
                        type: "url",
                        varType: "string"
                    })}
                /> : null
            }

            {fields.company.collect ?
                <Input
                    label="Company"
                    type="text"
                    placeholder="GoZen"
                    disabled={isPreview}
                    required={fields.company.required}
                    error={error?.company ?? ""}
                    onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                        id: "company",
                        answer: e.target.value,
                        type: "text",
                        varType: "string"
                    })}
                /> : null
            }

            {fields.role.collect ?
                <Input
                    label="Role"
                    type="text"
                    placeholder="Role"
                    disabled={isPreview}
                    required={fields.role.required}
                    error={error?.role ?? ""}
                    onChange={(e) => useTestimonialStore.getState().setCustomDetails({
                        id: "role",
                        answer: e.target.value,
                        type: "text",
                        varType: "string"
                    })}
                /> : null
            }

            {fields.companyLogo.collect ?
                <UploadPicture
                    required={fields.companyLogo.required}
                    label="Company Logo"
                    onChange={(file) => useUtilStore.getState().setFile("companyLogo", file)}
                /> : null
            }

        </div>
    );
}