import { useParams } from "react-router-dom";
import { RatingWidget } from "./rating/main.rating";
import { GenerateProfilePic } from "@utils/generateProfile";
import { HeroWidget } from "./hero/main.hero";
import { LogosWidget } from "./logos/main.logos";
import { ListWidget } from "./list/main.list";
import { ProfileCarousalWidget } from "./profileCarousal/main.profileCarousal";
import { ProfileGridWidget } from "./profileGrid/main.profile";
import { HighlightWidgets } from "./highLights/main.highlights";



export function WidgetPreviewPage() {

    const { id } = useParams<{ id: string; }>();

    return (
        <div className="h-screen w-full flex items-center justify-center p-4 bg-gray-100">

            {id === "w1" ?
                <RatingWidget
                    type="rating1"
                    rating={4}
                    text="from 150 testimonials"
                /> : null
            }

            {id === "w2" ?
                <HeroWidget
                    settings={{
                        carousel: true,
                        align: "center",
                        showAsCard: true,
                        showShadow: true
                    }}
                    testimonials={[
                        {
                            id: "123",
                            customerDetails: {
                                profilePic: {
                                    answer: GenerateProfilePic("A"), type: "", varType: ""
                                },
                                name: { answer: "Jhon", type: "", varType: "" },
                                role: { answer: "CEO", type: "", varType: "" },
                                company: { answer: "Pied Piper", type: "", varType: "" },
                            },
                            testimonial: {
                                text: {
                                    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                    imageAttachments: []
                                },
                                audio: null,
                                rating: 4,
                                video: null
                            }
                        },
                        {
                            id: "321",
                            customerDetails: {
                                profilePic: {
                                    answer: GenerateProfilePic("A"), type: "", varType: ""
                                },
                                name: { answer: "Jhon", type: "", varType: "" },
                                role: { answer: "CEO", type: "", varType: "" },
                                company: { answer: "Pied Piper", type: "", varType: "" },
                            },
                            testimonial: {
                                text: {
                                    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                    imageAttachments: []
                                },
                                audio: null,
                                rating: 4,
                                video: null
                            }
                        }
                    ]}
                /> : null
            }

            {id === "w3" ?
                <HighlightWidgets
                    testimonials={[
                        {
                            id: "123",
                            customerDetails: {
                                profilePic: {
                                    answer: GenerateProfilePic("A"), type: "", varType: ""
                                },
                                name: { answer: "Jhon", type: "", varType: "" },
                                role: { answer: "CEO", type: "", varType: "" },
                                company: { answer: "Pied Piper", type: "", varType: "" },
                            },
                            testimonial: {
                                text: {
                                    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                    imageAttachments: []
                                },
                                audio: null,
                                rating: 4,
                                video: null
                            }
                        }
                    ]}
                /> : null
            }

            {id === "w5" ?
                <LogosWidget
                    logos={[]}
                    settings={{
                        showAsCard: true,
                        animate: true,
                        showShadow: false,
                        layout: "marquee"
                    }}
                /> : null
            }

            {id === "w4" ?
                <ListWidget
                    testimonials={[
                        {
                            id: "123",
                            customerDetails: {
                                profilePic: {
                                    answer: GenerateProfilePic("A"), type: "", varType: ""
                                },
                                name: { answer: "Jhon", type: "", varType: "" },
                                role: { answer: "CEO", type: "", varType: "" },
                                company: { answer: "Pied Piper", type: "", varType: "" },
                            },
                            type: "video",
                            testimonial: {
                                text: {
                                    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                    imageAttachments: []
                                },
                                audio: null,
                                rating: 4,
                                video: null
                            }
                        },
                        {
                            id: "321",
                            customerDetails: {
                                profilePic: {
                                    answer: GenerateProfilePic("A"), type: "", varType: ""
                                },
                                name: { answer: "Jhon", type: "", varType: "" },
                                role: { answer: "CEO", type: "", varType: "" },
                                company: { answer: "Pied Piper", type: "", varType: "" },
                            },
                            type: "text",
                            testimonial: {
                                text: {
                                    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                    imageAttachments: []
                                },
                                audio: null,
                                rating: 4,
                                video: null
                            }
                        }
                    ]}
                /> : null
            }

            {id === "w6" ?
                <> popup widget </> : null
            }

            {id === "w7" ?
                <ProfileGridWidget
                    testimonials={[
                        {
                            id: "321",
                            customerDetails: {
                                profilePic: {
                                    answer: GenerateProfilePic("A"), type: "", varType: ""
                                },
                                name: { answer: "Jhon", type: "", varType: "" },
                                role: { answer: "CEO", type: "", varType: "" },
                                company: { answer: "Pied Piper", type: "", varType: "" },
                            },
                            type: "text",
                            testimonial: {
                                text: {
                                    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                    imageAttachments: []
                                },
                                audio: null,
                                rating: 4,
                                video: null
                            }
                        }
                    ]}
                    settings={{
                        isCarousel: true,
                        isGrid: false,
                        renderAsCard: true
                    }}
                /> : null
            }

            {id === "w8" ?
                <ProfileCarousalWidget
                    testimonials={
                        [
                            {
                                id: "111",
                                customerDetails: {
                                    profilePic: {
                                        answer: GenerateProfilePic("A"), type: "", varType: ""
                                    },
                                    name: { answer: "Jhon", type: "", varType: "" },
                                    role: { answer: "CEO", type: "", varType: "" },
                                    company: { answer: "Pied Piper", type: "", varType: "" },
                                },
                                type: "video",
                                testimonial: {
                                    text: null,
                                    audio: null,
                                    rating: 4,
                                    video: {
                                        url: "ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8",
                                        thumbnail: ""
                                    }
                                }
                            },
                            {
                                id: "222",
                                customerDetails: {
                                    profilePic: {
                                        answer: GenerateProfilePic("A"), type: "", varType: ""
                                    },
                                    name: { answer: "Jhon", type: "", varType: "" },
                                    role: { answer: "CEO", type: "", varType: "" },
                                    company: { answer: "Pied Piper", type: "", varType: "" },
                                },
                                type: "text",
                                testimonial: {
                                    text: {
                                        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti ullam, magnam modi, sit similique nulla id maiores tenetur corrupti eum nostrum, earum at architecto deserunt aliquam illo non debitis facere? earum at architecto deserunt aliquam illo non debitis facere?",
                                        imageAttachments: []
                                    },
                                    video: null,
                                    audio: null,
                                    rating: 4,
                                }
                            },
                            {
                                id: "333",
                                customerDetails: {
                                    profilePic: {
                                        answer: GenerateProfilePic("A"), type: "", varType: ""
                                    },
                                    name: { answer: "Jhon", type: "", varType: "" },
                                    role: { answer: "CEO", type: "", varType: "" },
                                    company: { answer: "Pied Piper", type: "", varType: "" },
                                },
                                type: "video",
                                testimonial: {
                                    text: null,
                                    audio: null,
                                    rating: 4,
                                    video: {
                                        url: "ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8",
                                        thumbnail: ""
                                    }
                                }
                            },
                        ]}
                /> : null
            }

        </div>
    );
}