import { Button } from "@components/button";
import { CheckBox } from "@components/checkBox";
import { StoreKeys, useCommonStore, useFormStore, useTestimonialStore, } from "@store";
import { CommonFields } from "./commonFields";
import { CustomFields } from "./customFields/customFields.main";
import { PurifyHTML } from "@utils/purifyHTML";
import { useCustomLabels, useFormDesign, useIsPreview, useTestimonialApiClient } from "@hooks";
import { useSchema } from "./useSchema";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "@utils/cn";


export function CustomDetails() {

    const [searchParams, setSearchParams] = useSearchParams();

    const isPreview = useIsPreview();
    const labels = useCustomLabels();
    const schema = useSchema();

    const customerConsent = useTestimonialStore((store) => store.customConsentAccepted);

    const design = useFormDesign();

    const { formId, title, fontFamily } = useFormStore((store) => ({
        formId: store.id,
        title: store.customDetails.title,
        fontFamily: store.design.fontFamily
    }));

    const [error, setError] = useState<{ [key: string]: string; }>({});

    const { submit } = useTestimonialApiClient({});

    const onSubmit = async () => {
        try {

            const customDetails = useTestimonialStore.getState().customerDetails;

            const answers: { [key: string]: any; } = {};

            /* validate custom details fields */
            Object.keys(customDetails).forEach(key => answers[key] = customDetails[key].answer);

            const valid = schema.safeParse({ ...answers });

            if (valid.success === false) {

                const errorsMsg: { [key: string]: string; } = {};

                valid.error.errors.forEach(error => errorsMsg[error.path[0]] = error.message);

                setError(errorsMsg);

                return;

            }

            // await onUploadFiles();

            const outcome = useFormStore.getState().thankYou.showCustomPage;

            const { type, customerDetails } = useTestimonialStore.getState();

            await submit.mutateAsync({
                formId,
                id: useTestimonialStore.getState().id,
                type,
                outcome: outcome && outcome.length ? outcome : "default",
                hiddenFields: useTestimonialStore.getState().hiddenFields,
                submittedAt: new Date().toISOString(),
                metaData: useTestimonialStore.getState().metaData,
                testimonial: useTestimonialStore.getState().testimonial,
                customerDetails,
                startedAt: useTestimonialStore.getState().startedAt,
                landedAt: useTestimonialStore.getState().landedAt,
                reward: useTestimonialStore.getState().reward
            });

            const name = (customerDetails["name"]?.answer as string) ?? "";
            const email = (customDetails["email"]?.answer as string) ?? "";

            searchParams.set("name", name);
            searchParams.set("email", email);

            setSearchParams(searchParams);

            useCommonStore.getState().setCurrentStep("thankyou");

            /* clear local storage cache */
            localStorage.removeItem(StoreKeys.testimonial);
            localStorage.removeItem(StoreKeys.common);

            useTestimonialStore.getState().clear();

        } catch (err) {
            console.log(err);
        }
    };

    // const onUploadFiles = async () => {
    //     try {

    //         setUploading(true);

    //         const uploadableFields: any = {};

    //         /* get upload urls */
    //         const responseId = useTestimonialStore.getState().id;

    //         const files = useUtilStore.getState().files;

    //         const parsedFiles: Api.TestimonialClient.getUploadUrlParams["files"] = [];

    //         Object.keys(files).forEach(key => {

    //             parsedFiles.push({
    //                 key: key,
    //                 type: files[key]?.type as string,
    //                 contentLength: files[key]?.size as number
    //             });

    //         });

    //         const { urls } = await mutateAsync({
    //             formId,
    //             responseId,
    //             source: "customDetails",
    //             files: parsedFiles
    //         });

    //         /* transform custom details field */
    //         Object.keys(files).forEach(key => {

    //             const uploadKey = urls.filter(field => field.key === key)[0];

    //             uploadableFields[key] = uploadKey.key;

    //         });

    //         const uploadFilesPromise = urls.map(url => {

    //             return TestimonialClient.uploadFile({
    //                 contentType: files[url.key]?.type as string,
    //                 fields: url.fields,
    //                 file: files[url.key] as File,
    //                 key: url.key,
    //                 url: url.url
    //             }, (progress) => setProgress((prev) => prev + progress));

    //         });

    //         await Promise.all(uploadFilesPromise);

    //         setUploading(false);

    //         return uploadableFields;

    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    return (
        <div style={{ fontFamily }}>

            <h1
                className="text-2xl font-bold tracking-wide cursor-default select-none"
                dangerouslySetInnerHTML={{ __html: PurifyHTML(title) }}
                style={{
                    color: design.fontColor
                }}
            />

            <CommonFields error={error} />

            <CustomFields error={error} />

            <div className="flex items-start justify-between space-x-2 px-2 mt-3">

                <CheckBox
                    disable={isPreview}
                    className={cn("w-[16px] h-[16px] mt-1", {
                        "ml-2": design.enableRTL
                    })}
                    defaultChecked={customerConsent}
                    onClick={() => useTestimonialStore.getState().setCustomConsent(!customerConsent)}
                />

                <p
                    className="w-[96%] text-[14px] xs:text-xs font-normal text-gray-600 select-none"
                    dangerouslySetInnerHTML={{ __html: PurifyHTML(labels.consent) }}
                    style={{
                        color: `${design.fontColor}60`
                    }}
                />

            </div>

            <Button
                disabled={isPreview || submit.isPending}
                loading={submit.isPending}
                onClick={() => onSubmit()}
            >
                Submit
            </Button>

        </div>
    );
}

export default CustomDetails;