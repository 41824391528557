import { cn } from "@utils/cn";
import { ReactNode } from "react";

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
    text?: string,
    children?: ReactNode,
    tooltip?: boolean,
    size?: "xs" | "sm" | "md" | "lg" | "xl",
    variant?: "label" | "primary",
}

export function Text({ className, children, size, text, variant, tooltip, ...props }: Props) {
    return (
        <p
            {...props}
            className={cn({
                "block text-sm font-normal leading-6 text-gray-700": variant === "label",
                "": size === "xs",
                "": size === "sm",
                "": size === "md",
                "": size === "lg",
                "": size === "xl",
                "ml-[1px]": variant === "label",
                "text-sm font-medium": !size && !variant,
                "flex items-center space-x-1": tooltip ? true : false
            }, className)}
        >
            {children ?? text}
        </p>
    );
}