import { cn } from "@utils/cn";
import { MdFormatQuote } from "react-icons/md";


type Props = {
    testimonials: (Pick<WolType.Type, "id" | "customerDetails" | "testimonial">[]),
    settings?: {
        animate: boolean;
    };
};

export function HighlightWidgets({ testimonials }: Props) {

    return (
        <div className="flex flex-col items-center space-x-4 space-y-4 overflow-x-hidden">

            <TestimonialList
                idx={1}
                testimonials={testimonials}
            />

            <TestimonialList
                idx={2}
                testimonials={testimonials}
            />

        </div>
    );
}


function TestimonialList({ idx, testimonials }: { idx: number, testimonials: Props["testimonials"], }) {

    console.log({ idx });

    return (
        <div className={cn({
            "flex items-center justify-around space-x-4": idx === 1,
            "flex flex-row-reverse items-center justify-around space-x-4": idx === 2
        })}>

            {testimonials.map(({ customerDetails, id, testimonial }) => (
                <div
                    key={id}
                    className="flex items-center space-x-2 mx-4 bg-white border border-gray-200 shadow-lg p-4 rounded-lg"
                >

                    <img
                        src={customerDetails.profilePic?.answer}
                        className="w-8 h-8 border border-gray-300 rounded-full"
                    />

                    <div className={cn("space-x-1 text-center cursor-default flex items-center", {})}>

                        <p
                            className="text-base font-medium tracking-wide"
                            dangerouslySetInnerHTML={{
                                __html: testimonial.text?.content ?? ""
                            }}
                        />

                        <MdFormatQuote className="text-gray-600 w-5 h-5" />

                    </div>

                </div>
            ))}

        </div>
    );
}