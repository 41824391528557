
type Props = {
    content: string,
    attachments: string[];
};

export function TextTestimonial({ content, }: Props) {

    return (
        <div>

            <p
                className=""
                dangerouslySetInnerHTML={{ __html: content }}
            />

        </div>
    );
}