import { cn } from "@utils/cn";
import { Form } from "./sections/form.main";

export function FormPage() {

    return (
        <div
            className={cn(`
                w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative dotted-bg
                flex flex-col items-center justify-center 
            `)}
            style={{
                // height: `${1000}px`
                height: 'auto',
                minHeight: '100vh',
            }}
        >

            <Form />

        </div>
    );
}