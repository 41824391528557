import { QueryKeys, useGetQueryData } from "@hooks";
import { useFormStore } from "@store";
import { cn } from "@utils/cn";
import { GenerateProfilePic } from "@utils/generateProfile";
import { useRef } from "react";


export function CustomerDetails() {

    const rtl = useFormStore((store) => store.design.enableRTL);

    const query = useGetQueryData<Api.TestimonialClient.get>([QueryKeys.testimonial.get]);

    const imgRef = useRef<HTMLImageElement>(null);

    const customerDetails = query?.testimonial?.customerDetails ?? {};

    const profileUrl = customerDetails?.profileUrl ?? null;
    const name = customerDetails?.name ?? null;

    if (!profileUrl && !name) return null;

    return (
        <div className="flex items-center space-x-3 pb-3">

            <img
                ref={imgRef}
                src={customerDetails?.profileUrl ?? GenerateProfilePic(customerDetails.name[0])}
                className={cn("flex-shrink-0 object-cover object-center w-12 h-12 border border-gray-300 rounded-full cursor-pointer", {
                    "ml-2": rtl
                })}
                onInvalid={() => {

                    if (imgRef.current) {
                        imgRef.current.src = GenerateProfilePic(customerDetails.name[0]);
                    }
                }}
            />

            <p className={cn("text-xl font-medium tracking-wide", {
                "mr-2": rtl
            })}>
                {customerDetails.name}
            </p>

        </div>
    );
}