import { Form } from "./sections/form.main";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "@utils/cn";
import { useTestimonialStore } from "@store";


export function FormEmbedPage() {

    const [searchParams] = useSearchParams();

    const removeBackground = Boolean(searchParams.get("removeBackground") ?? "false");

    useEffect(() => {

        window.parent.postMessage({ type: "getMetaInfo" }, "*");

        window.addEventListener("message", (e) => getEmbedMetaInfo(e), false);

        return window.removeEventListener("message", (e) => getEmbedMetaInfo(e), false);

    }, []);

    const getEmbedMetaInfo = (event: MessageEvent) => {

        const { type, payload } = event.data;

        if (type === "gz-metaInfo") {

            const hiddenFields: any = {};

            if (payload.hiddenFields) {

                payload.hiddenFields.split(",").forEach((item: string) => {

                    const [name, value] = item.split("=");

                    if (value !== "xxxxx") hiddenFields[name] = value;

                });

                useTestimonialStore.getState().setHiddenFields({ hiddenFields });

            }

            useTestimonialStore.getState().setMetaData({
                origin: payload.origin,
                userAgent: payload.userAgent
            });

        }
    };


    if (removeBackground === true) {
        return (
            <div className="w-screen h-screen">

                <Form />

            </div>
        );
    }

    return (
        <div
            className={cn(`
                w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative dotted-bg
                flex flex-col items-center justify-center 
            `)}
            style={{
                // height: `${1000}px`
                height: 'auto',
                minHeight: '100vh',
            }}
        >

            <Form />

        </div>
    );

}