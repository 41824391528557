import { Button } from "@components/button";
import { Config } from "@config";
import { useFormDesign, useIsPreview } from "@hooks";
import { useCommonStore, useFormStore } from "@store";
import { cn } from "@utils/cn";
import { PurifyHTML } from "@utils/purifyHTML";
import { useRef } from "react";


export function SideBar() {

    const isPreview = useIsPreview();

    const design = useFormDesign();

    const wheelSpun = useCommonStore((store) => store.spinWheelSpun);

    const { logo, title, message, button, fontFamily } = useFormStore((store) => ({
        logo: store.design.logo,
        title: store.reward.page.title,
        message: store.reward.page.message,
        button: store.reward.page.button,
        fontFamily: store.design.fontFamily
    }));

    const { cardColor, fontColor } = useFormDesign();

    const imgRef = useRef<HTMLImageElement>(null);


    return (
        <div
            className={cn(`
                md:w-[50%] h-full bg-white flex items-center border-r shadow-md
            `, {
                "w-[80%]": isPreview,
                "w-[40%]": isPreview === false
            })}
            style={{
                background: cardColor
            }}
        >

            <div className="flex-col items-center justify-center px-4 space-y-2">

                <div className="inline-flex items-center justify-center border border-gray-200 rounded-full p-3 shadow-lg mt-4">

                    <img
                        ref={imgRef}
                        src={logo ?? Config.misc.placeHolderLogo}
                        alt="dummy image"
                        className="w-[40px] h-[40px]"
                        onError={() => { if (imgRef.current) imgRef.current.src = Config.misc.placeHolderLogo; }}
                    />

                </div>

                <div className="space-y-3 px-2" style={{ fontFamily }}>

                    <h1
                        className="text-2xl font-bold tracking-wide"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(title) }}
                        style={{
                            color: fontColor
                        }}
                    />

                    <p
                        className="text-base font-medium text-gray-700 tracking-wide"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(message) }}
                        style={{
                            color: fontColor
                        }}
                    />

                    <Button
                        disabled={wheelSpun || isPreview}
                        className="mt-3"
                        onClick={() => useCommonStore.getState().setSpinWheelSpun(true)}
                        style={{
                            background: design.primaryColor,
                            fontFamily: design.fontFamily
                        }}
                    >
                        {button}
                    </Button>


                </div>

            </div>

        </div>
    );
}