import { Rating } from "@components/rating";
import { cn } from "@utils/cn";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";


type Props = {
    testimonials?: (Pick<WolType.Type, "id" | "customerDetails" | "testimonial">)[],
    style?: {
        bgColor: string,
        textColor: string,
        ratingColor: string;
    },
    settings?: {
        carousel: boolean,
        align: "left" | "center" | "right",
        showAsCard: boolean,
        showShadow: boolean;
    };
};

export function HeroWidget({ settings, testimonials }: Props) {

    const isCarousel = settings?.carousel && (testimonials?.length ?? 0) > 1;

    const [currIdx, setCurrIdx] = useState(0);

    console.log({ currIdx });

    const testimonial = testimonials && testimonials[currIdx];

    console.log({ testimonial });


    return (
        <div className={cn("w-[50%] p-6 space-y-2 bg-white", {
            "flex items-center justify-between": isCarousel,
            "border rounded-xl": settings?.showAsCard ?? true,
            "shadow-lg": settings?.showShadow ?? true
        })}>

            {isCarousel ?
                <button
                    className={cn(`
                        bg-gray-100 hover:bg-gray-200 rounded-full p-2 
                        transition-all duration-200 ease-in-out outline-none
                    `)}
                    onClick={() => {

                        if (currIdx - 1 >= 0) setCurrIdx((prev) => prev - 1);

                    }}
                >

                    <FaChevronLeft className="w-4 h-4 text-gray-600" />

                </button> : null
            }


            {testimonial ?
                <div className={cn("flex flex-col justify-center items-center", {
                    "items-start": settings?.align === "left",
                    "items-end": settings?.align === "right"
                })}>

                    <img
                        src={testimonial.customerDetails.profilePic?.answer}
                        className="rounded-full border border-gray-300 shadow-md"
                    />

                    <div className={cn("space-x-1 text-center mt-2 cursor-default", {
                        "text-left": settings?.align === "left",
                        "text-right": settings?.align === "right"
                    })}>

                        <span className="font-bold text-xl opacity-50">“</span>

                        <span
                            className="text-base font-normal tracking-wide"
                            dangerouslySetInnerHTML={{
                                __html: testimonial.testimonial.text?.content ?? ""
                            }}
                        />

                        <span className="font-bold text-xl opacity-50 inline-block">”</span>

                    </div>

                    <div className="mt-4">

                        <Rating
                            rated={4}
                            readonly
                        />

                    </div>

                    <div className={cn("text-center mt-2 cursor-default", {
                        "text-left": settings?.align === "left",
                        "text-right": settings?.align === "right"
                    })}>

                        <p className="text-sm font-semibold tracking-wide">
                            {testimonial.customerDetails?.name?.answer ?? ""}
                        </p>


                        <p className="text-sm font-normal tracking-wide text-gray-600">
                            {testimonial.customerDetails?.role?.answer ?? ""} of {testimonial.customerDetails?.company?.answer ?? ""}
                        </p>

                    </div>

                </div> : null
            }

            {isCarousel ?
                <button
                    className={cn(`
                        bg-gray-100 hover:bg-gray-200 rounded-full p-2 
                        transition-all duration-200 ease-in-out outline-none
                    `)}
                    onClick={() => {

                        if (currIdx + 1 < (testimonials?.length ?? 0)) setCurrIdx((prev) => prev + 1);

                    }}
                >

                    <FaChevronRight className="w-4 h-4 text-gray-600" />

                </button> : null
            }

        </div>
    );
}