import { useTestimonialStore } from "@store";
import { cn } from "@utils/cn";
import { useEffect, useState } from "react";
import { PiStarFill } from "react-icons/pi";


export function CollectRating() {

    const starts = new Array(5).fill(1);

    const [hoveredItem, setHoveredItem] = useState(0);

    const rating = useTestimonialStore((store) => store.testimonial.rating);

    useEffect(() => {

        if (rating === 0) useTestimonialStore.getState().setRating(5);

    }, []);

    const onHoverEnter = (val: string) => {

        if (val) {

            setHoveredItem(parseInt(val));

        }

    };


    return (
        <div className="flex items-center space-x-1">

            {starts.map((_, idx) => (
                <div
                    key={idx}
                    id={`${idx + 1}`}
                    className="cursor-pointer"
                    onMouseEnter={(e) => onHoverEnter((e.currentTarget.id))}
                    onMouseLeave={() => setHoveredItem(0)}
                    onClick={() => useTestimonialStore.getState().setRating(idx + 1)}
                >

                    <PiStarFill
                        key={idx}
                        className={cn("w-8 h-8 text-gray-400 transition-all duration-300 ease-in-out", {
                            "text-yellow-400 ": idx + 1 <= hoveredItem || idx + 1 <= rating
                        })}
                    />

                </div>
            ))}

        </div>
    );
}