import { useCallback, useEffect, useRef, useState } from "react";
import { Controls } from "../video/control";
import { MediaRecodingState } from "../video/main.video";
import { useLatestState } from "@hooks";
import { ControlSettings } from "../video/settings";
import { AudioPreview } from "./preview";
import { useFormStore } from "@store";
import { ReplayAudio } from "./replay";


export function AudioTestimonial() {

    const settings = useFormStore((store) => store.settings);

    const [formattedTimer, setFormattedTimer] = useState("00:00");
    const [audioSourceId, setAudioSourceId] = useState("");

    const audioRef = useRef<HTMLAudioElement>(null);
    const mediaSteam = useRef<MediaStream | null>(null);
    const recordedAudioRef = useRef<HTMLVideoElement>(null);
    const recordingTimerRef = useRef<any>(null);

    const visualizerRecorder = useRef<MediaRecorder | null>(null);
    const visualizerChunks = useRef<BlobPart[]>([]);
    const visualizerPreview = useRef<HTMLVideoElement | null>(null);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const blobChunks = useRef<BlobPart[]>([]);

    const [recordingState, setRecordingState, recordingStateRef] = useLatestState<MediaRecodingState>("not-recoding");
    const [statingTimer, setStartingTimer, startingTimerRef] = useLatestState<number>(3);
    const [_, setTimer, timerRef] = useLatestState<number>(0);

    useEffect(() => setupAudio(), []);

    const setupAudio = useCallback(() => {

        navigator.mediaDevices.getUserMedia({
            // audio: {
            //     deviceId: audioSourceId,
            //     noiseSuppression: true,
            //     autoGainControl: true
            // },
            audio: true
        }).then((stream) => {

            if (!audioRef.current) return;

            /* setting timer */
            setTimer(settings.maxRecordDuration);

            const min = Math.floor(settings.maxRecordDuration / 60).toString().padStart(2, '0');
            const sec = Math.floor(settings.maxRecordDuration % 60).toString().padStart(2, '0');

            setFormattedTimer(`${min}:${sec}`);

            const audioDeviceId = stream.getAudioTracks()[0].getCapabilities().deviceId;

            setAudioSourceId(audioDeviceId ?? "");

            mediaSteam.current = stream;

            mediaRecorderRef.current = new MediaRecorder(stream, {
                // videoBitsPerSecond: 8000000,
                // audioBitsPerSecond: 128000,
                // bitsPerSecond: 40000,
                mimeType: "audio/webm"
            });

            audioRef.current.srcObject = stream;
            audioRef.current.muted = true;
            audioRef.current.play();

            /* record canvas visualization */

            const ele = document.getElementById('audio-visualizer') as HTMLCanvasElement;

            if (ele) {

                const stream = ele.captureStream(30);

                visualizerRecorder.current = new MediaRecorder(stream, { mimeType: "video/webm" });

            }

        });

    }, [audioSourceId]);

    const startRecordingTimer = () => {

        if (recordingTimerRef.current) return;

        let timerId = setInterval(() => {

            const min = Math.floor(timerRef.current / 60).toString().padStart(2, '0');
            const sec = Math.floor(timerRef.current % 60).toString().padStart(2, '0');

            setFormattedTimer(`${min}:${sec}`);

            if (timerRef.current === 0) {
                clearInterval(recordingTimerRef.current);
                stopRecording();
                setTimer(1);
                return;
            }

            setTimer((prev) => prev - 1);

        }, 1000);

        setTimer((prev) => prev - 1);

        recordingTimerRef.current = timerId;

    };

    const startRecording = () => {

        setRecordingState("starting");

        const timer = setInterval(() => {

            if (startingTimerRef.current === 1) {

                clearInterval(timer);

                if (!mediaSteam.current) return 0;

                if (mediaRecorderRef.current) {

                    mediaRecorderRef.current.ondataavailable = (blb) => blobChunks.current.push(blb.data);

                    mediaRecorderRef.current.start(10);

                }

                setStartingTimer(1);

                startRecordingTimer();

                setRecordingState("recoding");

                return;

            } else setStartingTimer(startingTimerRef.current - 1);

            /* record preview canvas screens */

            if (visualizerRecorder.current) {

                visualizerRecorder.current.start(10);

                visualizerRecorder.current.ondataavailable = e => visualizerChunks.current.push(e.data);

            }

        }, 1000);

    };

    const stopRecording = () => {

        if (recordingStateRef.current !== "recoding" || !mediaRecorderRef.current) return;

        if (recordingTimerRef.current) clearInterval(recordingTimerRef.current);

        if (mediaSteam.current) mediaSteam.current.getTracks().forEach(track => track.stop());

        mediaRecorderRef.current.stop();

        const rawBlob = new Blob(blobChunks.current, { type: "audio/mp4" });

        const url = URL.createObjectURL(rawBlob);

        setRecordingState("recorded");

        setTimeout(() => { if (recordedAudioRef.current) recordedAudioRef.current.src = url; }, 100);

        stopCapturingCanvas();

    };

    const stopCapturingCanvas = () => {

        if (!mediaRecorderRef.current) return;

        mediaRecorderRef.current.stop();

        const previewBlob = new Blob(visualizerChunks.current, { type: "video/webm" });

        const videoUrl = URL.createObjectURL(previewBlob);

        setTimeout(() => { if (visualizerPreview.current) visualizerPreview.current.src = videoUrl; }, 100);

    };

    const onStart = () => {

        if (recordingState === "starting") return;

        if (recordingState === "recoding") stopRecording();
        else startRecording();

    };

    const onRestart = () => {

        setRecordingState("not-recoding");

        mediaSteam.current = null;

        mediaRecorderRef.current = null;

        recordingTimerRef.current = null;

        mediaRecorderRef.current = null;

        blobChunks.current = [];

        setStartingTimer(3);

        setTimer(0);

        setupAudio();

        /* reset preview video */
        visualizerRecorder.current = null;
        visualizerPreview.current = null;
        visualizerChunks.current = [];

    };

    const onPlayRecordedVideo = (play: boolean) => {

        if (!recordedAudioRef.current || !visualizerPreview.current) return;

        if (play) {

            recordedAudioRef.current.play();

            visualizerPreview.current.play();

        } else {

            recordedAudioRef.current.pause();

            visualizerPreview.current.pause();

        }

    };

    if (recordingState === "recorded") {
        return (
            <ReplayAudio
                replayVideoRef={visualizerPreview}
                ref={recordedAudioRef}
                blobChunks={blobChunks}
                onRestart={onRestart}
                onPlay={onPlayRecordedVideo}
            />
        );
    }

    return (
        <div className="rounded-xl relative">

            <div className="relative">

                <ControlSettings
                    isRecordingVideo={false}
                    recordingState={recordingState}
                    activeSourceId={{ videoId: "", audioId: audioSourceId }}
                    onSetActiveSourceId={(params) => setAudioSourceId(params.audioId)}
                    onSetup={setupAudio}
                />

                <AudioPreview
                    mediaRecorderRef={mediaRecorderRef}
                    audioRef={audioRef}
                    recordingState={recordingState}
                    startingTimer={statingTimer}
                />

            </div>

            <Controls
                formattedTimer={formattedTimer}
                isVideo={false}
                mediaSteam={mediaSteam}
                recordingState={recordingState}
                onStart={onStart}
            />

        </div>
    );
}