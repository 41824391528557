import { Button } from "@components/button";
import { useFormDesign, useIsPreview } from "@hooks";
import { useCommonStore, useFormStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";
import { Fragment, useState } from "react";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import { GoGift } from "react-icons/go";
import { ThankyouVideo } from "./video";


export function ThankYou() {

    const isPreview = useIsPreview();

    const design = useFormDesign();

    const {
        thankYou, allowReward, rewardBtnText, fontFamily, thankyouVideo
    } = useFormStore((store) => ({
        thankYou: store.thankYou,
        allowReward: store.reward.allow,
        rewardBtnText: store.reward.buttonText,
        fontFamily: store.design.fontFamily,
        thankyouVideo: store.thankYou.thankyouVideo
    }));

    const [show, setShow] = useState(true);

    return (
        <Fragment>

            <div className="overflow-hidden relative p-2" style={{ fontFamily }}>

                <div className="space-y-2 mb-4">

                    <h1
                        className="text-2xl font-bold tracking-wide"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(thankYou.title) }}
                        style={{
                            color: design.fontColor
                        }}
                    />

                    <p
                        className="text-base font-medium text-gray-700 tracking-wide"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(thankYou.message) }}
                        style={{
                            color: design.fontColor
                        }}
                    />

                </div>

                {thankyouVideo && thankyouVideo.url ? <ThankyouVideo /> : null}

                {/* show CTA */}
                {thankYou.cta.text && thankYou.cta.url ?
                    <Button
                        disabled={isPreview}
                        className="text-sm font-medium tracking-wider"
                        onClick={() => window.open(thankYou.cta.url, "_blank")}
                    >
                        {thankYou.cta.text}
                    </Button> : null
                }

                {allowReward ?
                    <Button
                        disabled={isPreview}
                        className="text-sm font-medium tracking-wider space-x-2"
                        onClick={() => useCommonStore.getState().setCurrentStep("reward")}
                    >

                        <GoGift className="w-5 h-5 mx-2" />

                        <p className="">
                            {rewardBtnText}
                        </p>

                    </Button> : null
                }

            </div>

            {isPreview === false && show ?
                <Realistic
                    autorun={{ speed: 0.2, duration: 5000 }}
                    onInit={(obj) => {

                        obj.confetti({
                            particleCount: 500,
                            spread: 180,
                            angle: 90,
                            shapes: ["square"],
                            origin: {
                                x: 10
                            }
                        })?.then(() => setShow(false));

                    }}
                    globalOptions={{
                        useWorker: true
                    }}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    className="absolute top-0 left-0 w-screen h-screen"
                /> : null
            }

        </Fragment >
    );
}

export default ThankYou;