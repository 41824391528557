import { cn } from "@utils/cn";
import { Avatar } from "./avatar";
import { FaQuoteLeft } from "react-icons/fa";

type Props = {
    testimonials: (Pick<WolType.Type, "id" | "customerDetails" | "type" | "testimonial">[]),
    settings?: {
        height: number,
        width: number,
        animate: boolean;
    };
};

export function ListWidget({ testimonials, }: Props) {

    return (
        <div className="space-y-4 w-full overflow-y-auto">

            {testimonials.map(({ customerDetails, id, type, testimonial }) => (
                <div
                    key={id}
                    className={cn(`
                        inline-flex w-auto items-center justify-center space-x-4 
                        py-4    
                    `)}
                >

                    <Avatar
                        type={type}
                        profilePic={customerDetails.profilePic?.answer}
                        videoUrl={testimonial.video?.url ?? null}
                    />

                    <div className="w-[40%] space-y-2">

                        <div className="bg-blue-200 w-8 h-8 p-2 flex items-center justify-center rounded-xl">

                            <FaQuoteLeft className="text-primary" />

                        </div>

                        <div className="text-base font-medium mt-4">

                            "{testimonial.text?.content}"

                        </div>

                        <div className="">

                            <h2 className="text-sm leading-5 font-medium tracking-wide cursor-default">
                                {customerDetails?.name?.answer ?? "John"}
                            </h2>

                            <div className="text-xs font-normal tracking-wide cursor-default">

                                {customerDetails?.companyUrl ?
                                    <a
                                        rel="nofollow"
                                        href={customerDetails?.companyUrl.answer ?? ""}
                                    >

                                        {customerDetails?.role?.answer ?? "CEO"} of {customerDetails?.company?.answer ?? "Pied"}

                                    </a> :
                                    <>
                                        {customerDetails?.role?.answer ?? "CEO"} of {customerDetails?.company?.answer ?? "Pied"}
                                    </>
                                }

                            </div>

                        </div>

                        {/* 

                            
                            - name
                              role  
                            
                            
                        */}

                    </div>

                </div>
            ))}

        </div>
    );
}