import { useIsPreview } from "@hooks";
import { useEffect, useRef, useState } from "react";

function animateBars(analyser: AnalyserNode, canvas: any, canvasCtx: any, dataArray: Uint8Array, bufferLength: number) {

    analyser.getByteFrequencyData(dataArray);

    canvasCtx.fillStyle = '#000';

    const HEIGHT = canvas.height;

    // var barWidth = Math.ceil(canvas.width / bufferLength) * 2.5;
    const barWidth = 30;

    let barHeight;
    let x = 0;

    for (var i = 0; i < bufferLength; i++) {

        barHeight = (dataArray[i] / 255) * HEIGHT ?? 5;

        canvasCtx.fillStyle = 'rgb(' + 255 + ',' + 255 + ',' + 255 + ')';

        canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

        x += barWidth + 10;

    }

}

type Props = {
    stream: MediaStream | null;
};

export function AudioVisualizer({ stream }: Props) {

    const isPreview = useIsPreview();

    const [analyzerData, setAnalyzerData] = useState<{
        analyzer: AnalyserNode | null,
        dataArr: Uint8Array | null,
        bufferLength: number;
    }>({
        analyzer: null,
        bufferLength: -1,
        dataArr: null
    });

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const started = useRef(false);

    useEffect(() => {

        if (!started.current && stream) {

            audioAnalyzer();

            started.current = true;
        }

    }, [stream]);

    useEffect(() => { draw(); }, [analyzerData.analyzer, analyzerData.bufferLength, analyzerData.dataArr]);

    const audioAnalyzer = () => {

        if (!stream) return;

        const audioCtx = new AudioContext();

        const analyzer = audioCtx.createAnalyser();

        analyzer.fftSize = 2048;

        const bufferLength = 6;

        const dataArr = new Uint8Array(bufferLength);

        const source = audioCtx.createMediaStreamSource(stream);;

        source.connect(analyzer);
        source.connect(audioCtx.destination);

        // source.onended = () => source.disconnect();

        setAnalyzerData({ analyzer, bufferLength, dataArr });

    };

    const draw = () => {

        const { analyzer, bufferLength, dataArr } = analyzerData;

        const canvas = canvasRef.current;

        if (!canvas || !analyzer || !dataArr) return;

        const canvasCtx = canvas.getContext("2d");

        const animate = () => {

            requestAnimationFrame(animate);

            canvas.width = canvas.width;

            animateBars(analyzer, canvas, canvasCtx, dataArr, bufferLength);

        };

        animate();

    };

    if (isPreview) {
        return (
            <div className="flex items-center justify-center space-x-1">

                {new Array(5).fill(0).map((_, idx) => (
                    <div
                        key={idx}
                        className="bg-white h-1 w-1 rounded-md"
                    />
                ))}

            </div>
        );
    }

    return (
        <canvas
            style={{}}
            ref={canvasRef}
            className="w-[50px] h-[30px]"
        />
    );

}