import { Config } from "@config";
import { useRef, useState } from "react";
import ImagePlaceHolder from "@assets/image-placeholder.png";
import { cn } from "@utils/cn";
import { Text } from "@components/text";
import { useCustomLabels, useIsPreview } from "@hooks";
import { z } from "zod";

type Props = {
    label: string,
    required: boolean,
    selectLabel?: string,
    onChange: (file: File) => void;
};

export function UploadPicture({ label, required, selectLabel, onChange }: Props) {

    const isPreview = useIsPreview();
    const labels = useCustomLabels();

    const ref = useRef<HTMLImageElement>(null);

    const [error, setError] = useState("");
    const [selectedFile, setSelectedFile] = useState<File>();

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {

            const file = (e.target.files as FileList)[0];

            const schema = z.object({
                size: z.number().min(1, labels.companyLogoFieldRequired)
                    .max(
                        Config.maxAllowedSizeIn.bytes,
                        labels.maxFileSize
                    ),
                fileType: z.string().min(1, labels.companyLogoFieldRequired)
                    .refine((type) => {

                        const types = ["image/png", "image/jpg", "image/jpeg"];

                        return types.includes(type);

                    }, { message: labels.invalidFileType })
            });

            const valid = schema.safeParse({ size: file.size, fileType: file.type });

            if (!valid.success) {
                setError(valid.error.errors[0].message);
                return;
            }

            const url = URL.createObjectURL(file);

            if (ref.current) ref.current.src = url;

            setSelectedFile(file);

            onChange(file);

        } catch (err) {
            console.log(err);

        }
    };

    return (
        <div className="space-y-3">

            <div className="flex items-center space-x-1">

                <Text
                    text={label}
                    variant="label"
                    className="font-medium"
                />

                {required ? <span className="text-red-500 font-medium"> * </span> : null}

            </div>



            <input
                disabled={isPreview}
                id="change-space-logo"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                className="hidden"
                onChange={(e) => onFileChange(e)}
            />

            <div className="flex items-center space-x-3 px-1">

                <img
                    ref={ref}
                    onInvalid={() => { }}
                    src={selectedFile ? URL.createObjectURL(selectedFile) : ImagePlaceHolder}
                    className={cn("w-[50px] h-[50px]")}
                />

                <label
                    htmlFor="change-space-logo"
                    className={cn(`
                        inline-block border-2 border-gray-300 py-2 px-4 rounded-md font-medium tracking-wide
                        transition-all duration-200 ease-in-out cursor-pointer text-sm text-gray-700
                    `, {
                        "hover:text-primary hover:border-blue-500 hover:bg-blue-100 cursor-default": isPreview === false
                    })}
                >
                    {selectLabel ?? "Pick an image"}
                </label>

            </div>

            {error ?
                <span className={cn(`
                    text-red-500 flex flex-row items-center space-x-1 
                    mt-1 px-1 text-sm font-normal text-left
                `)}>

                    * {error}

                </span > : null
            }


        </div>
    );
}