import { useIsPreview } from "@hooks";
import { Fragment, useRef } from "react";
import DummyAudioPreview from "@assets/audio-preview.png";
import { cn } from "@utils/cn";
import { MediaRecodingState } from "../video/main.video";
import { LiveAudioVisualizer } from "./audioVisualizer";

type Props = {
    recordingState: MediaRecodingState,
    audioRef: React.RefObject<HTMLAudioElement>,
    startingTimer: number,
    mediaRecorderRef: React.MutableRefObject<MediaRecorder | null>;
};

export function AudioPreview({ recordingState, startingTimer, audioRef, mediaRecorderRef }: Props) {

    const isPreview = useIsPreview();

    const divRef = useRef<HTMLDivElement>(null);

    return (
        <Fragment>

            {isPreview ?
                <img
                    src={DummyAudioPreview}
                    className="h-[240px] w-full"
                    alt=""
                /> :
                <div
                    ref={divRef}
                    className="h-[300px] border-gray-300 bg-gray-100 border rounded-t-lg relative"
                >

                    <audio
                        autoPlay
                        ref={audioRef}
                        className="invisible"
                    />

                    <div
                        className={cn("w-[50%] bg-black border border-dashed absolute top-[50%] left-[25%]", {
                            "invisible": recordingState !== "not-recoding",
                            "visible": recordingState === "not-recoding"
                        })}
                        style={{ borderWidth: "1px" }}
                    />

                    <div className={cn("w-full h-full rounded-t-xl px-2 flex items-center justify-center", {
                        "invisible": recordingState === "not-recoding",
                        "visible": recordingState === "recoding"
                    })}>

                        <LiveAudioVisualizer
                            recordingState={recordingState}
                            width={divRef.current ? divRef.current?.clientWidth / 2 : 300}
                            height={300}
                            mediaRecorder={mediaRecorderRef.current as MediaRecorder}
                        />

                    </div>

                </div>
            }

            {recordingState === "starting" ?
                <div className={cn(`
                    absolute top-0 left-0 w-full h-full z-30 
                    flex flex-col items-center justify-center
                `)}>

                    <h1 className="text-7xl font-semibold text-black">
                        {startingTimer}
                    </h1>

                    <p className="text-black font-medium">
                        <i className="mx-1">
                            Smile for the audio
                        </i>
                        🙂
                    </p>

                </div> : null
            }

        </Fragment>
    );
}