
export function WidgetPage() {

    return (
        <div className="flex items-center justify-center border border-red-400 h-screen w-screen bg-gray-100">

            {/* <RatingWidget
                type="rating1"
                rating={4}
                text="from 150 testimonials"
            /> */}

            {/* <HeroWidget
                settings={{
                    carousel: true,
                    align: "center",
                    showAsCard: true,
                    showShadow: true
                }}
                testimonials={[
                    {
                        id: "123",
                        customerDetails: {
                            profilePic: GenerateProfilePic("A"),
                            name: "Jhon",
                            role: "CEO",
                            company: "Pied Piper",
                        },
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    },
                    {
                        id: "321",
                        customerDetails: {
                            profilePic: GenerateProfilePic("B"),
                            name: "nbnbnb",
                            role: "CEO",
                            company: "mmnmnmn",
                        },
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    }
                ]}
            /> */}

            {/* <LogosWidget
                logos={[]}
                settings={{
                    showAsCard: true,
                    animate: true,
                    showShadow: false,
                    layout: "marquee"
                }}
            /> */}


            {/* <ListWidget
                testimonials={[
                    {
                        id: "123",
                        customerDetails: {
                            profilePic: GenerateProfilePic("A"),
                            name: "Jhon",
                            role: "CEO",
                            company: "Pied Piper",
                        },
                        type: "video",
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    },
                    {
                        id: "321",
                        customerDetails: {
                            profilePic: GenerateProfilePic("B"),
                            name: "nbnbnb",
                            role: "CEO",
                            company: "mmnmnmn",
                        },
                        type: "text",
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    }
                ]}
            /> */}

            {/* <ProfileCarousalWidget
                testimonials={
                    [
                        {
                            id: "123",
                            customerDetails: {
                                profilePic: GenerateProfilePic("A"),
                                name: "Jhon",
                                role: "CEO",
                                company: "Pied Piper",
                            },
                            type: "video",
                            testimonial: {
                                text: null,
                                audio: null,
                                rating: 4,
                                video: {
                                    url: "ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8",
                                    thumbnail: ""
                                }
                            }
                        },
                        {
                            id: "8321",
                            customerDetails: {
                                profilePic: GenerateProfilePic("B"),
                                name: "nbnbnb",
                                role: "CEO",
                                company: "mmnmnmn",
                            },
                            type: "text",
                            testimonial: {
                                text: {
                                    content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                    imageAttachments: []
                                },
                                audio: null,
                                rating: 4,
                                video: null
                            }
                        },
                        {
                            id: "1623",
                            customerDetails: {
                                profilePic: GenerateProfilePic("A"),
                                name: "Jhon",
                                role: "CEO",
                                company: "Pied Piper",
                            },
                            type: "video",
                            testimonial: {
                                text: null,
                                audio: null,
                                rating: 4,
                                video: {
                                    url: "ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8",
                                    thumbnail: ""
                                }
                            }
                        },
                        {
                            id: "1243",
                            customerDetails: {
                                profilePic: GenerateProfilePic("A"),
                                name: "Jhon",
                                role: "CEO",
                                company: "Pied Piper",
                            },
                            type: "video",
                            testimonial: {
                                text: null,
                                audio: null,
                                rating: 4,
                                video: {
                                    url: "ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8",
                                    thumbnail: ""
                                }
                            }
                        },
                        {
                            id: "1233",
                            customerDetails: {
                                profilePic: GenerateProfilePic("A"),
                                name: "Jhon",
                                role: "CEO",
                                company: "Pied Piper",
                            },
                            type: "video",
                            testimonial: {
                                text: null,
                                audio: null,
                                rating: 4,
                                video: {
                                    url: "ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8",
                                    thumbnail: ""
                                }
                            }
                        },
                        {
                            id: "1233",
                            customerDetails: {
                                profilePic: GenerateProfilePic("A"),
                                name: "Jhon",
                                role: "CEO",
                                company: "Pied Piper",
                            },
                            type: "video",
                            testimonial: {
                                text: null,
                                audio: null,
                                rating: 4,
                                video: {
                                    url: "ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8",
                                    thumbnail: ""
                                }
                            }
                        },
                    ]}
            /> */}

            {/* <ProfileGridWidget
                testimonials={[
                    {
                        id: "321",
                        customerDetails: {
                            profilePic: GenerateProfilePic("B"),
                            name: "nbnbnb",
                            role: "CEO",
                            company: "mmnmnmn",
                        },
                        type: "text",
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    },
                    {
                        id: "321",
                        customerDetails: {
                            profilePic: GenerateProfilePic("B"),
                            name: "nbnbnb",
                            role: "CEO",
                            company: "mmnmnmn",
                        },
                        type: "text",
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    },
                    {
                        id: "321",
                        customerDetails: {
                            profilePic: GenerateProfilePic("B"),
                            name: "nbnbnb",
                            role: "CEO",
                            company: "mmnmnmn",
                        },
                        type: "text",
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    },
                    {
                        id: "321",
                        customerDetails: {
                            profilePic: GenerateProfilePic("B"),
                            name: "nbnbnb",
                            role: "CEO",
                            company: "mmnmnmn",
                        },
                        type: "text",
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    },
                    {
                        id: "321",
                        customerDetails: {
                            profilePic: GenerateProfilePic("B"),
                            name: "nbnbnb",
                            role: "CEO",
                            company: "mmnmnmn",
                        },
                        type: "text",
                        testimonial: {
                            text: {
                                content: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dicta voluptatum officia fugiat non sequi nisi repudiandae tenetur at.
                        Ab dolorum reiciendis autem consequatur eaque ipsum quibusdam accusantium.`,
                                imageAttachments: []
                            },
                            audio: null,
                            rating: 4,
                            video: null
                        }
                    }
                ]}
                settings={{
                    isCarousel: true,
                    isGrid: true,
                    renderAsCard: true
                }}
            /> */}


        </div>
    );
} 0;