import { PoweredBy } from "@components/branding";
import { Loading } from "@components/loading";
import { useTestimonialApiClient } from "@hooks";
import { cn } from "@utils/cn";
import MeshGradient from "mesh-gradient.js";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

const COLORS = [
    "#eb75b6",
    "#ddf3ff",
    "#6e3deb",
    "#c92f3c"
];


export function CaseStudyPage() {

    const testimonialId = useParams().id as string;

    const { getCaseStudyQuery } = useTestimonialApiClient({
        getCaseStudy: {
            apiParams: {
                id: testimonialId
            }
        }
    });

    useEffect(() => { getCaseStudyQuery.refetch(); }, []);

    const { isLoading, isFetching } = getCaseStudyQuery;

    const caseStudy = getCaseStudyQuery?.data;

    const coreSections = Object.keys(caseStudy as any ?? []).filter(item => item !== "title" && item !== "highlights");

    const gradient = new MeshGradient();

    useEffect(() => {

        gradient.initGradient("#canvas", COLORS);

        gradient?.changePosition(500);

    }, []);


    return (
        <div
            className={cn(`
                w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative dotted-bg
                flex items-start justify-center 
            `)}
            style={{
                // height: `${1000}px`
                height: 'auto',
                minHeight: '100vh'
            }}
        >

            <canvas
                id="canvas"
                className="w-full h-full absolute top-0 left-0 z-[10]"
            />

            <div className="bg-white px-8 py-6 z-40 rounded-lg w-[70%] min-h-[80%] my-20">

                {isLoading || isFetching ? <Loading text="loading..." /> :
                    <Fragment>

                        <br />

                        <h1 className="text-2xl font-bold text-center tracking-wide px-6 cursor-default">
                            {caseStudy?.title ?? ""}
                        </h1> <br /> <br />

                        {caseStudy?.highlights.length ?
                            <div>

                                <h2 className="text-lg font-bold tracking-wide cursor-default">
                                    Highlight
                                </h2>

                                <ul className="list-disc px-7 mt-2">
                                    {caseStudy.highlights.map((item, idx) => (
                                        <li
                                            key={idx}
                                            className="text-base font-normal tracking-wide cursor-default"
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul> <br />

                            </div> : null
                        }

                        {/* {testimonial?.caseStudy ?
                            <Fragment>

                                <ShowCaseTestimonial testimonial={testimonial} />

                            </Fragment> : null
                        } */}

                        {coreSections.map((item: any, idx) => (
                            <Fragment>

                                <div key={idx}>

                                    <h2 className="text-xl font-bold tracking-wide cursor-default">
                                        {(caseStudy as any)[item].title}
                                    </h2>

                                    <p className="text-base font-normal mt-2 text-left tracking-wide cursor-default">
                                        {(caseStudy as any)[item].content}
                                    </p>

                                </div>

                            </Fragment>
                        ))}

                    </Fragment>
                }
                <PoweredBy />

            </div>

        </div >
    );
}