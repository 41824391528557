import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { useRef, useState } from "react";
import ImagePlaceholder from "@assets/image-placeholder.png";
import { MdOutlineClose } from "react-icons/md";
import { useFormStore, useUtilStore } from "@store";
import { v4 as uuid } from "uuid";
import { LuImagePlus } from "react-icons/lu";


export function ImageAttachments() {

    const ref = useRef<any>(null);
    const [error, setError] = useState("");

    const fontFamily = useFormStore((store) => store.design.fontFamily);
    const imageAttachments = useUtilStore((store) => store.imageAttachments);

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {

            const formats = ["png", "jpg", "jpeg", "gif"];

            const files = (e.target?.files ?? []) as FileList;

            for (let i = 0; i < files.length; i++) {

                const file = files[i];

                const format = file.type.split("/")[1].toLowerCase();

                const isValidImageType = formats.includes(format);

                if (!isValidImageType) {
                    setError("invalid image type");
                    e.target.value = "";
                    break;
                }

                const sizeInMb = Math.floor((file.size / 1024) / 1024);

                if (sizeInMb > 10) {
                    setError("image too large. image should less 3mb.");
                    e.target.value = "";
                    break;
                }

                useUtilStore.getState().setImageAttachments(uuid(), file);

            }

        } catch (err) {
            console.log(err);

        }
    };

    return (
        <div
            className="mt-2"
            style={{ fontFamily }}
        >

            <Text
                variant="label"
                text="Select images"
            />

            <input
                id="change-space-logo"
                type="file"
                multiple={true}
                accept="image/png, image/jpg, image/jpeg"
                className="hidden"
                onChange={(e) => onFileChange(e)}
            />

            <div className={cn(`
                space-x-3 mt-1 border-2 border-dashed border-gray-300 rounded-md 
                w-full max-w-full
            `, {
                "space-x-0": Object.keys(imageAttachments).length
            })}>

                <label
                    ref={ref}
                    htmlFor="change-space-logo"
                    className={cn(`
                        text-gray-400 py-1 rounded-md font-medium tracking-wide
                        transition-all duration-200 ease-in-out cursor-pointer text-sm w-full   
                        flex items-center justify-center visible min-h-[100px]
                    `, {
                        "hidden": Object.keys(imageAttachments).length
                    })}
                >

                    <Text text="Max file size 10mb" />

                </label>

                <div className={cn(`
                    flex items-center justify-center space-x-2 overflow-x-scroll p-2 
                    border border-red-500 w-full max-w-full visible
                `, {
                    "hidden": !Object.keys(imageAttachments).length
                })}>

                    <div
                        className={cn(`
                            w-[100px] h-[80px] border-2 border-dashed border-gray-300 text-gray-400
                            rounded-lg flex items-center justify-center 
                        `)}
                        onClick={() => ref.current.click()}
                    >

                        <LuImagePlus className="w-6 h-6" />

                    </div>

                    {Object.keys(imageAttachments).map((key) => {

                        const file = imageAttachments[key];

                        return (
                            <div className="flex items-center w-auto h-full relative space-x-2">

                                <img
                                    onInvalid={() => { }}
                                    src={file ? URL.createObjectURL(file) : ImagePlaceholder}
                                    className={cn("w-auto h-[80px] rounded-md")}
                                />

                                <div
                                    className={cn(`
                                        bg-white absolute right-2 top-2 rounded-full p-1 hover:bg-gray-200 
                                        duration-300 transition-all ease-in-out
                                    `)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        useUtilStore.getState().removeAttachment(key);
                                    }}
                                >
                                    <MdOutlineClose className="w-5 h-5 text-gray-700" />
                                </div>

                            </div>
                        );
                    })}

                </div>

                {error ?
                    <span
                        className={cn(`
                            text-red-500 flex flex-row items-center space-x-1 mt-1 px-1 
                            text-sm font-normal text-left
                        `)}
                    >
                        * {error}
                    </span > : null
                }

            </div>

        </div>
    );
}