import { cn } from '@utils/cn';
import {
    XIcon, ProductHunt, LinkedinIcon, RedditIcon, GoogleIcon, FacebookIcon,
    InstagramIcon, CapterraIcon, TrustPilotIcon, G2GroundIcon, YelpIcon,
    YoutubeIcon, TikTokIcon, ShopifyIcon, PlayStoreIcon, AppStoreIcon
} from '.';
import { Logo } from '@components/icons';

type Props = {
    video?: boolean,
    source: WolType.Source,
    sourceLink: string,
    className?: string;
};


export function TestimonialSource({ video, source, sourceLink, className }: Props) {
    return (
        <div
            className={cn({
                "absolute top-4 right-4": video,
                "cursor-pointer": sourceLink
            })}
            title={source}
            onClick={() => window.open(sourceLink, "_blank")}
        >

            {source === "gozenTestimonial" ? <Logo className={cn('w-10 h-10', className)} /> : null}

            {source === "x" ? <XIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "productHunt" ? <ProductHunt className={cn("w-10 h-10", className)} /> : null}

            {source === "linkedin" ? <LinkedinIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "reddit" ? <RedditIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "google" ? <GoogleIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "facebook" ? <FacebookIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "instagram" ? <InstagramIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "capterra" ? <CapterraIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "trustPilot" ? <TrustPilotIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "g2" ? <G2GroundIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "yelp" ? <YelpIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "youtube" ? <YoutubeIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "tiktok" ? <TikTokIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "shopify" ? <ShopifyIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "playStore" ? <PlayStoreIcon className={cn("w-10 h-10", className)} /> : null}

            {source === "appStore" ? <AppStoreIcon className={cn("w-10 h-10", className)} /> : null}

        </div>
    );
}
