import { Config } from "@config";
import { QueryKeys, useGetQueryData } from "@hooks";
import { cn } from "@utils/cn";
import { PurifyHTML } from "@utils/purifyHTML";


export function TextTestimonial() {

    const query = useGetQueryData<Api.TestimonialClient.get>([QueryKeys.testimonial.get]);

    const { content, imageAttachments } = query.testimonial.testimonial.text;

    return (
        <div>

            <p
                className="text-base font-normal tracking-wide"
                dangerouslySetInnerHTML={{ __html: PurifyHTML(content) }}
            />

            {imageAttachments.length ?
                <div className="mt-4">

                    {imageAttachments.map((image, idx) => {
                        return (
                            <img
                                key={idx}
                                src={Config.cdn() + image}
                                className={cn("rounded-lg w-full h-auto border border-gray-400", {
                                    "w-auto": imageAttachments.length !== 1
                                })}
                            />
                        );
                    })}

                </div> : null
            }

        </div>
    );
}