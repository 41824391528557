import { useFormStore } from "@store";
import { useEffect } from "react";
import MeshGradient from 'mesh-gradient.js';

export function useGradientBackground() {

    const background = useFormStore((store) => store.design.background);

    useEffect(() => {

        if (background.gradient) {

            const gradient = new MeshGradient();

            gradient.initGradient("#gradient-canvas", background.gradient.colors);

            gradient?.changePosition(Math.floor(Math.random() * 1000));

        }


    }, [JSON.stringify(background.gradient)]);

    return;

}