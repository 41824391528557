import { useCommonStore, useFormStore } from "@store";
import { cn } from "@utils/cn";
import { ReactNode, useEffect, useRef } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { motion } from "framer-motion";
import { useFormDesign, useIsPreview } from "@hooks";
import { Config } from "@config";
import WebFront from "webfontloader";

type Props = {
    children: ReactNode;
};

export function FormCardLayout({ children }: Props) {

    const isPreview = useIsPreview();

    const { cardColor } = useFormDesign();

    const { currentStep, previewType } = useCommonStore((store) => ({
        currentStep: store.currentStep,
        previewType: store.previewType
    }));

    const { logo, fontFamily, alignment, rtl } = useFormStore((store) => ({
        logo: store.design.logo,
        fontFamily: store.design.fontFamily,
        alignment: store.design.alignment,
        rtl: store.design.enableRTL
    }));

    const ref = useRef<HTMLImageElement>(null);

    useEffect(() => WebFront.load({ google: { families: [fontFamily] } }), [fontFamily]);

    return (
        <motion.div
            className={cn(`
                md:w-[40%] h-auto bg-white rounded-lg shadow-lg border p-6 space-y-4 
                py-10 my-10 z-[100] border-transparent
            `, {
                "py-6": currentStep === "collect",
                "sm:w-[80%]": isPreview && previewType === "laptop",
                "xs:w-[92%] xs:shadow-md xs:border xs:border-gray-200 xs:px-4 xs:py-6": isPreview === false
            })}
            style={{
                background: cardColor
            }}
            initial={{
                height: "auto"
            }}
            animate={{
                height: "auto"
            }}
            dir={rtl ? "rtl" : "ltr"}
        >

            <div className={cn("flex items-center justify-between", {})}>

                <div className={cn("w-full flex items-center", {
                    "justify-start": alignment === "left",
                    "justify-center": alignment === "center",
                    "justify-end": alignment === "right" && rtl,
                })}>

                    <img
                        ref={ref}
                        src={logo ?? Config.misc.placeHolderLogo}
                        alt="dummy image"
                        className="w-[50px] h-[50px]"
                        onError={() => {

                            if (ref.current) ref.current.src = Config.misc.placeHolderLogo;

                        }}
                    />

                </div>

                {currentStep === "collect" ?
                    <div
                        className={cn(`
                            p-2 rounded-full border border-gray-400 shadow-lg hover:bg-gray-100
                            transition-all duration-300 ease-in-out cursor-pointer group
                        `)}
                        onClick={() => useCommonStore.getState().setCurrentStep("welcome")}
                    >

                        <IoMdArrowBack className="w-6 h-6 group-hover:scale-x-110" />

                    </div> : null
                }

            </div>

            {children}

        </motion.div>
    );
}

export default FormCardLayout;