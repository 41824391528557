import { useFormStore, useTestimonialStore } from "@store";
import { Fragment } from "react";
import { TextTestimonial } from "./text/text";
import { useIsPreview } from "@hooks";
import { PreviewCollectionScreen } from "./preview";
import { ResponseTitle } from "./title";
import { ResponsePageDescription } from "./description";
import { CollectRating } from "./rating";
import { VideoTestimonial } from "./video/main.video";
import { AudioTestimonial } from "./audio/audio.main";


export function CollectTestimonial() {

    const isPreview = useIsPreview();

    const type = useTestimonialStore((store) => store.type);
    const collectRating = useFormStore((store) => store.response.collectRating);

    return (
        <Fragment>

            <div className="space-y-2">

                <ResponseTitle />

                <ResponsePageDescription />

            </div>

            {collectRating ? <CollectRating /> : null}

            {isPreview ? <PreviewCollectionScreen /> :
                <Fragment>

                    {type === "video" ? <VideoTestimonial /> : null}

                    {type === "audio" ? <AudioTestimonial /> : null}

                    {type === "text" ? <TextTestimonial /> : null}

                </Fragment>
            }

        </Fragment >
    );
}

export default CollectTestimonial;