
type Params = {
    isPreview?: boolean,
    isPreviewTypeMobile: boolean,
};

export function getSize({ isPreview, isPreviewTypeMobile }: Params) {

    const isMobileView = isPreviewTypeMobile || window.innerWidth < 600;

    /* only get 60% width for the spin wheel */
    const sectionWidth = isMobileView ? window.innerWidth : Math.floor(0.60 * window.innerWidth);

    const screenSize = isPreview ? 740 : sectionWidth;

    const divSize = isMobileView ? Math.floor(0.80 * screenSize) : Math.floor(0.70 * screenSize);

    /* center circle */
    const outerCircleSize = Math.floor(0.14 * divSize);

    const innerCircleSize = Math.floor(0.75 * outerCircleSize);

    const pos = Math.floor(divSize / 2) - outerCircleSize;

    /* triangle */
    const arrowSize = Math.floor(0.20 * divSize);

    const arrowPosition = Math.floor(divSize / 2);

    return {
        divSize,
        centerCircle: {
            outer: outerCircleSize,
            inner: innerCircleSize,
            top: pos,
            left: pos
        },
        arrow: {
            size: arrowSize > 100 ? 100 : arrowSize,
            position: isMobileView ? arrowPosition - 12 : arrowPosition - 30
        },
    };

}