import { useIsPreview } from "@hooks";
import { cn } from "@utils/cn";

type Props = {
    error?: string,
    field: Form.State["customDetails"]["customFields"][0],
};

export function LongTextField({ error, field }: Props) {

    const isPreview = useIsPreview();

    return (
        <div>

            <label className="text-sm font-medium text-gray-700 px-1">
                {field.label}
                {field.required ? <span className="text-red-500 font-medium"> * </span> : null}
            </label>

            <textarea
                disabled={isPreview}
                placeholder={field.placeHolder ?? "Enter your text here."}
                className={cn(`
                    w-full rounded-md border border-gray-300 p-2 text-sm font-normal mt-1
                    focus:outline-none focus:ring-1 focus:ring-blue-400 focus:ring-offset-1
                `)}
                onChange={() => { }}
            />

            {error ?
                <span
                    className={cn(`
                        text-red-500 flex flex-row items-center space-x-1
                        px-1 text-sm font-normal text-left
                    `)}
                >
                    * {error}
                </span > : null
            }


        </div>
    );
}