import { useCallback, useEffect, useRef, useState } from "react";
import { Controls } from "./control";
import { VideoPreview } from "./videoPreview";
import { ControlSettings } from "./settings";
import { useLatestState } from "@hooks";
import { useFormStore } from "@store";
import { RecordedVideo } from "./recordedVideo";

export type MediaRecodingState = "not-recoding" | "starting" | "recoding" | "recorded";


export function VideoTestimonial() {

    const settings = useFormStore((store) => store.settings);

    const videoRef = useRef<HTMLVideoElement>(null);
    const mediaSteam = useRef<MediaStream | null>(null);
    const recordedVideoRef = useRef<HTMLVideoElement>(null);

    const blobChunks = useRef<BlobPart[]>([]);
    const recordingTimerRef = useRef<any>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const [_, setTimer, timerRef] = useLatestState<number>(0);
    const [recordingState, setRecordingState, recordingStateRef] = useLatestState<MediaRecodingState>("not-recoding");
    const [statingTimer, setStartingTimer, startingTimerRef] = useLatestState<number>(3);

    const [activeSourceId, setActiveSourceId] = useState({
        audioId: "",
        videoId: ""
    });
    const [formattedTimer, setFormattedTimer] = useState("00:00");

    useEffect(() => { setupVideo(); }, []);

    const setupVideo = useCallback(() => {

        navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: activeSourceId.videoId,
                // width: 360,
                // height: 360,
                noiseSuppression: true,
                autoGainControl: true,
                frameRate: {
                    ideal: 60,
                    min: 30,
                    max: 60
                },
            },
            audio: {
                deviceId: activeSourceId.audioId,
            },
        }).then((stream) => {

            if (!videoRef.current) return;

            setTimer(settings.maxRecordDuration);

            const min = Math.floor(settings.maxRecordDuration / 60).toString().padStart(2, '0');
            const sec = Math.floor(settings.maxRecordDuration % 60).toString().padStart(2, '0');

            setFormattedTimer(`${min}:${sec}`);

            const videoDeviceId = stream.getVideoTracks()[0].getCapabilities().deviceId;
            const audioDeviceId = stream.getAudioTracks()[0].getCapabilities().deviceId;

            setActiveSourceId({
                audioId: audioDeviceId ?? "",
                videoId: videoDeviceId ?? ""
            });

            mediaSteam.current = stream;

            mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: "video/webm" });

            videoRef.current.srcObject = stream;
            videoRef.current.muted = true;
            videoRef.current.play();

        }).catch(err => {
            console.log(err);
        });

    }, [activeSourceId]);

    const onPlayRecordedVideo = (play: boolean) => {

        if (!recordedVideoRef.current) return;

        if (play) recordedVideoRef.current.play();
        else recordedVideoRef.current.pause();

    };

    const onRestart = () => {

        setRecordingState("not-recoding");

        mediaSteam.current = null;

        mediaRecorderRef.current = null;

        recordingTimerRef.current = null;

        mediaRecorderRef.current = null;

        blobChunks.current = [];

        setStartingTimer(3);

        setTimer(0);

        setupVideo();

    };

    const startRecordingTimer = () => {

        if (recordingTimerRef.current) return;

        let timerId = setInterval(() => {

            const min = Math.floor(timerRef.current / 60).toString().padStart(2, '0');
            const sec = Math.floor(timerRef.current % 60).toString().padStart(2, '0');

            setFormattedTimer(`${min}:${sec}`);

            if (timerRef.current === 0) {
                clearInterval(recordingTimerRef.current);
                stopRecording();
                setTimer(1);
                return;
            }

            setTimer(() => timerRef.current - 1);

        }, 1000);

        setTimer(() => timerRef.current - 1);

        recordingTimerRef.current = timerId;

    };

    const startRecording = () => {

        setRecordingState("starting");

        const timer = setInterval(() => {

            if (startingTimerRef.current === 1) {

                clearInterval(timer);

                if (!mediaSteam.current) return 0;

                if (mediaRecorderRef.current) {

                    mediaRecorderRef.current.ondataavailable = (blb) => blobChunks.current.push(blb.data);

                    mediaRecorderRef.current.start(10);

                }

                setTimer(1);

                startRecordingTimer();

                setRecordingState("recoding");

                return;

            } else setStartingTimer(startingTimerRef.current - 1);

        }, 1000);

    };

    const stopRecording = () => {

        if (recordingStateRef.current !== "recoding" || !mediaRecorderRef.current) return;

        if (recordingTimerRef.current) {
            clearInterval(recordingTimerRef.current);
        }

        if (mediaSteam.current) {

            mediaSteam.current.getTracks().forEach(track => track.stop());

        }

        mediaRecorderRef.current.stop();

        const rawBlob = new Blob(blobChunks.current, { type: "video/webm" });

        const url = URL.createObjectURL(rawBlob);

        setRecordingState("recorded");

        setTimeout(() => {

            if (recordedVideoRef.current) {

                recordedVideoRef.current.src = url;

                recordedVideoRef.current.onloadedmetadata = () => {

                    if (recordedVideoRef.current) recordedVideoRef.current.play();

                };

            }

        }, 100);

    };

    const onStart = () => {

        if (recordingState === "starting") return;

        if (recordingState === "recoding") stopRecording();
        else startRecording();

    };

    if (recordingState === "recorded") {
        return (
            <RecordedVideo
                blobChunks={blobChunks}
                ref={recordedVideoRef}
                onRestart={onRestart}
                onPlay={onPlayRecordedVideo}
            />
        );
    };

    return (
        <div className="rounded-xl bg-black relative">

            <div className="relative">

                <ControlSettings
                    isRecordingVideo={true}
                    recordingState={recordingState}
                    activeSourceId={activeSourceId}
                    onSetActiveSourceId={setActiveSourceId}
                    onSetup={setupVideo}
                />

                <VideoPreview
                    recordingState={recordingState}
                    startingTimer={statingTimer}
                    videoRef={videoRef}
                />

            </div>

            <Controls
                isVideo={true}
                recordingState={recordingState}
                formattedTimer={formattedTimer}
                mediaSteam={mediaSteam}
                onStart={onStart}
            />

        </div>
    );
}