import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import { Config } from "@config";
import { QueryKeys, useGetQueryData } from "@hooks";
import ReactHlsPlayer from "react-hls-player";

export function AudioTestimonial() {

    const query = useGetQueryData<Api.TestimonialClient.get>([QueryKeys.testimonial.get]);

    const audio = query.testimonial.testimonial.audio;

    const playerRef = useRef<any>(null);

    const [paused, setPaused] = useState(false);

    const videoUrl = Config.cdn() + audio?.url;

    useEffect(() => {

        if (playerRef.current && audio) {
            playerRef.current.play();
        }

    }, []);

    return (
        <div className={cn(`
            flex flex-col items-center justify-center border border-gray-200 
            rounded-lg mt-4 bg-gray-100
        `)}>

            <div className="boxContainer w-auto my-6 space-x-2">

                <div className={cn("box", { "box1": paused === false })} />

                <div className={cn("box", { "box2": paused === false })} />

                <div className={cn("box", { "box3": paused === false })} />

                <div className={cn("box", { "box4": paused === false })} />

                <div className={cn("box", { "box5": paused === false })} />

            </div>

            <ReactHlsPlayer
                playerRef={playerRef}
                src={videoUrl}
                autoPlay
                controls
                onPlay={() => setPaused(false)}
                onPause={() => setPaused(true)}
                className="w-full m-auto rounded-md outline-none shadow-sm"
            />

        </div>
    );
}