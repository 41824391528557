import { Button, SecondaryButton } from "@components/button";
import { cn } from "@utils/cn";
import { forwardRef, useState } from "react";
import { FiVideo } from "react-icons/fi";
import { MdOutlineRefresh } from "react-icons/md";
import { GrPause } from "react-icons/gr";
import { GrPlay } from "react-icons/gr";
import { useCommonStore, useFormStore, useTestimonialStore } from "@store";
import { FileUploadingGIF } from "@components/fileUploadingGif";
import { useTestimonialApiClient } from "@hooks";

type Props = {
    blobChunks: React.MutableRefObject<BlobPart[]>,
    onPlay: (play: boolean) => void,
    onRestart: () => void,
};

export const RecordedVideo = forwardRef<HTMLVideoElement, Props>(({ blobChunks, onRestart, onPlay }, ref) => {

    const formId = useFormStore((store) => store.id);

    const [play, setPlay] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const rawBlob = new Blob(blobChunks.current, { type: "video/webm" });

    const { getUploadUrl, uploadFile } = useTestimonialApiClient({});

    const handlePlay = () => {
        setPlay(!play);
        onPlay(!play);
    };

    const onSubmit = async () => {
        try {

            setUploading(true);

            const data = await getUploadUrl.mutateAsync({
                formId,
                responseId: useTestimonialStore.getState().id,
                source: "testimonial",
                files: [{
                    contentLength: rawBlob.size,
                    key: "video",
                    type: "video/webm"
                }]
            });

            await uploadFile({
                url: data.urls[0].url,
                contentType: data.urls[0].contentType,
                fields: data.urls[0].fields,
                file: rawBlob,
                key: data.urls[0].key
            }, (progressVal) => setProgress(progressVal));

            const ele = document.getElementById("testimonial-preview") as HTMLVideoElement;

            if (!ele) return;

            const params: Testimonial.State["testimonial"]["video"] = {
                duration: ele.duration,
                size: Math.floor(rawBlob.size / 1024) / 1024, // converting bytes to mb
                format: "video/webm",
            };

            useTestimonialStore.getState().setVideoTestimonial(params);

            // await submit.mutateAsync({
            //     formId,
            //     event: {
            //         type: "video",
            //         testimonial: {
            //             video: { ...params },
            //             rating
            //         },
            //         landedAt: useTestimonialStore.getState().landedAt,
            //         startedAt: useTestimonialStore.getState().startedAt,
            //         metaData: useTestimonialStore.getState().metaData,
            //     }
            // });

            setUploading(false);

            useCommonStore.getState().setCurrentStep("customDetails");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="relative">

            <div className="group">

                <div className={cn(`    
                    rounded-xl w-full h-[300px] border-2 border-gray-800 
                    flex flex-col items-center justify-center 
                `)}>

                    <FileUploadingGIF
                        show={uploading}
                        progress={progress}
                    />

                    <video
                        id="testimonial-preview"
                        autoPlay
                        controls={false}
                        ref={ref}
                        className={cn(`w-full h-full object-cover rounded-xl`, {
                            "hidden": uploading,
                            "block": uploading === false
                        })}
                        onClick={() => handlePlay()}
                        onEnded={() => setPlay(false)}
                    />

                </div>

                <div
                    className={cn(`
                        rounded-full p-4 bg-black text-white absolute top-[30%] left-[45%] cursor-pointer 
                        hidden transition-all duration-300 ease-in-out 
                    `, {
                        "group-hover:block hidden": uploading === false
                    })}
                    onClick={() => handlePlay()}
                >

                    <span className="flex items-center justify-center">

                        {play ?
                            <GrPause className="w-10 h-10 text-white" /> :
                            <GrPlay className="w-10 h-10 text-white" />
                        }

                    </span>

                </div>

            </div>

            <SecondaryButton
                onClick={() => onRestart()}
                className="text-sm font-medium tracking-wide space-x-2"
            >

                <MdOutlineRefresh className="w-5 h-5 mx-2" />

                <p> Restart </p>

            </SecondaryButton>

            <Button
                disabled={uploading}
                loading={uploading}
                onClick={() => onSubmit()}
                className="text-sm font-medium tracking-wide"
            >

                <FiVideo className="w-5  h-5 text-white mx-2" />

                <p> Submit </p>

            </Button>

        </div>
    );

});;