import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import axios, { AxiosRequestConfig } from 'axios';
import { RequestInterceptor } from '@lib/axios/interceptor.ts';


const queryClient = new QueryClient();

axios.interceptors.request.use((config: AxiosRequestConfig) => RequestInterceptor(config));


ReactDOM.createRoot(document.getElementById('root')!).render(
    // <React.StrictMode>

    <ErrorBoundary
        fallback={import.meta.env.PROD ?
            <p> something went wrong!... </p> : null
        }
        onError={(err) => console.log(err)}
    >

        <BrowserRouter>

            <QueryClientProvider client={queryClient}>

                <App />

                <ReactQueryDevtools />

            </QueryClientProvider>

        </BrowserRouter>

    </ErrorBoundary>

    // </React.StrictMode>,
);

