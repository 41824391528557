import { Logo } from "@components/icons";
import { Config } from "@config";
import { useIsPreview } from "@hooks";
import { cn } from "@utils/cn";

export function PoweredBy() {

    const isPreview = useIsPreview();

    return (
        <div className={cn(`
            bg-white rounded-xl border border-gray-300 shadow-lg space-x-2
            px-4 py-2 absolute bottom-5 right-5 inline-flex items-center justify-center 
            cursor-pointer
        `, {
            "mt-10": isPreview
        })}
            onClick={() => window.open(Config.landingPage, "_blank")}
        >

            <Logo className="w-8 h-8" />

            <p className="text-base font-semibold tracking-wide">

                Powered by GoZen Testimonials

            </p>

        </div>
    );
}