import DummyLogo1 from '@assets/dummyLogos/logo1.png';
import DummyLogo2 from '@assets/dummyLogos/logo2.png';
import DummyLogo3 from "@assets/dummyLogos/logo3.png";
import DummyLogo4 from '@assets/dummyLogos/logo4.png';
import DummyLogo5 from '@assets/dummyLogos/logo5.png';
import DummyLogo6 from '@assets/dummyLogos/logo6.png';
import { cn } from '@utils/cn';

type Props = {
    logos: string[],
    style?: {},
    settings?: {
        layout: "grid" | "marquee",
        animate: boolean,
        showAsCard: boolean,
        showShadow: boolean;
    };
};

export function LogosWidget({ logos, settings }: Props) {

    logos = [DummyLogo1, DummyLogo2, DummyLogo3, DummyLogo4, DummyLogo5, DummyLogo6];

    return (
        <div className={cn("bg-white p-4 gap-4", {
            "border border-gray-200 rounded-xl": settings?.showAsCard,
            "shadow-md ": settings?.showShadow,
            "grid grid-cols-3 items-center": settings?.layout === "grid",
            "flex items-center justify-evenly": settings?.layout === "marquee",
            "w-full": settings?.animate
        })}>


            {logos.map(logo => (
                <img
                    src={logo}
                    className='m-auto w-[200px] h-[100px] relative'
                    style={{ objectFit: "contain" }}
                />
            ))}

        </div >
    );
}