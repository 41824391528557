import { Logo } from "@components/icons";
import { Rating } from "@components/rating";
import { cn } from "@utils/cn";


type Props = {
    type: "rating1" | "rating2" | "rating3",
    rating: number,
    text: string,

};

export function RatingWidget({ text, rating }: Props) {

    return (
        <div className="flex items-center space-x-4">

            <div className={cn(`
                text-xl font-semibold bg-black text-white rounded-lg w-12 h-14 
                flex items-center justify-center
            `)}>

                4.7

            </div>

            <div className="space-y-1 py-2">

                <Rating
                    rated={rating}
                    readonly
                />

                <p className="text-sm font-medium">
                    {text}
                </p>

            </div>

            <div className="">

                <Logo className="w-8 h-8" />

            </div>

        </div>
    );
}