import { useFormDesign, useIsPreview } from "@hooks";
import { useCommonStore, useFormStore, useTestimonialStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";
import { useEffect, useState } from "react";


export function ResponseTitle() {

    const isPreview = useIsPreview();

    const design = useFormDesign();

    const { response, fontFamily } = useFormStore((store) => ({
        response: store.response,
        fontFamily: store.design.fontFamily
    }));

    const { audio, text, video } = response;

    const previewTestimonialType = useCommonStore((store) => store.previewTestimonialType);
    const type = useTestimonialStore((store) => store.type);

    const [title, setTitle] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    useEffect(() => {

        if (type === "video") setTitle(video.title ?? "");
        else if (type === "audio") setTitle(audio.title ?? "");
        else setTitle(text.title ?? "");

    }, []);

    useEffect(() => {

        if (isPreview) {

            if (previewTestimonialType === "video") setPreviewTitle(video.title ?? "");
            else if (previewTestimonialType === "audio") setPreviewTitle(audio.title ?? "");
            else setPreviewTitle(text.title ?? "");

        }

    }, [audio.title, video.title, text.title, previewTestimonialType]);


    return (
        <h1
            className="text-2xl font-bold tracking-wide"
            dangerouslySetInnerHTML={{ __html: PurifyHTML(isPreview ? previewTitle : title) }}
            style={{
                fontFamily,
                color: design.fontColor
            }}
        />
    );
}