import { Rating } from "@components/rating";
import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaPause, FaPlay } from "react-icons/fa6";
import ReactCardFlip from 'react-card-flip';
import ReactHlsPlayer from "react-hls-player";

type Props = {
    testimonials: Pick<WolType.Type, "id" | "customerDetails" | "testimonial" | "type">[],
    settings?: {
        animate: boolean,
        nav: boolean,
    };
};

export function ProfileCarousalWidget({ testimonials, }: Props) {

    const ref = useRef<HTMLDivElement>(null);

    const [hoveredId, setHoveredId] = useState("");
    const [currIdx, setCurrIdx] = useState(0);

    console.log({ testimonials });

    return (
        <div className="w-[80%] h-[50%] flex items-center space-x-6">

            <div className="w-full border-green-500 relative">

                <button
                    className={cn(`
                        absolute top-[40%] left-0 border z-10 
                        bg-white hover:bg-gray-200 rounded-full p-2 
                        transition-all duration-200 ease-in-out outline-none
                    `)}
                    onClick={() => {

                        if (currIdx - 1 >= 0) setCurrIdx((prev) => prev - 1);

                        if (ref.current) {

                            const newPos = ref.current.scrollLeft - 300;

                            ref.current.scrollTo({ left: newPos, behavior: "smooth" });

                        }

                    }}
                >

                    <FaChevronLeft className="w-4 h-4 text-gray-600" />

                </button>

                <div
                    ref={ref}
                    className="flex items-center overflow-y-hidden overflow-x-scroll no-scrollbar"
                >

                    {testimonials.map(({ customerDetails, id, testimonial, type }) => (
                        <div
                            key={id}
                            className="mx-4 min-w-[260px] h-[300px]"
                            onMouseEnter={() => setHoveredId(id)}
                            onMouseLeave={() => setHoveredId("")}
                        >

                            <ReactCardFlip
                                key={id}
                                isFlipped={hoveredId === id}
                                flipDirection="horizontal"
                            >

                                <div className="">

                                    <img
                                        src={customerDetails.profilePic?.answer}
                                        className="w-[260px] h-[300px] object-cover border border-gray-300 rounded-lg shadow-md"
                                    />


                                    <div className="absolute bottom-4 left-4">

                                        <Rating
                                            rated={4}
                                            readonly
                                            iconProps={{
                                                className: "w-4 h-4 text-red"
                                            }}
                                        />

                                        <p className="text-base font-semibold tracking-wide mt-1">
                                            {customerDetails.name?.answer}
                                        </p>

                                        <p className="text-xs font-normal tracking-wide">
                                            {customerDetails.role?.answer} of {customerDetails.company?.answer}
                                        </p>

                                    </div>

                                </div>

                                <div className="rounded-xl border w-[260px] h-[300px] bg-white">

                                    <div
                                        className="absolute top-0 left-0 border w-full h-full rounded-lg p-4"
                                        style={{
                                            transformStyle: "preserve-3d",
                                        }}

                                    >

                                        <div >

                                            <p className="text-base font-semibold tracking-wide mt-1">
                                                {customerDetails.name?.answer}
                                            </p>

                                            <p className="text-xs font-normal tracking-wide">
                                                {customerDetails.role?.answer} of {customerDetails.company?.answer}
                                            </p>

                                        </div>

                                        <div className="w-full h-full">

                                            {type === "text" ?
                                                <p
                                                    className="text-sm font-normal tracking-wide mt-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: testimonial.text?.content ?? ""
                                                    }}
                                                /> : null
                                            }

                                            {type === "video" ? <Video url={testimonial.video?.url ?? ""} /> : null}

                                        </div>

                                    </div>

                                </div>

                            </ReactCardFlip>

                        </div>
                    ))}

                </div>

                <button
                    className={cn(`
                        absolute right-0 top-[40%] border
                        bg-white hover:bg-gray-200 rounded-full p-2 
                        transition-all duration-200 ease-in-out outline-none
                    `)}
                    onClick={() => {

                        if (currIdx + 1 < (testimonials?.length ?? 0)) setCurrIdx((prev) => prev + 1);

                        if (ref.current) {

                            const newPos = ref.current.scrollLeft + 300;

                            ref.current.scrollTo({ left: newPos, behavior: "smooth" });

                        }

                    }}
                >

                    <FaChevronRight className="w-4 h-4 text-gray-600" />

                </button>

            </div>

        </div>
    );
}

type VideoProps = {
    url: string;
};

function Video({ url }: VideoProps) {

    const playerRef = useRef<any>(null);

    const [play, setPlay] = useState(false);


    useEffect(() => {

        if (playerRef.current) {
            playerRef.current.play();
        }

    }, []);

    const onPlay = () => {

        if (play) {
            playerRef.current?.pause();
            setPlay(false);
        } else {
            playerRef.current?.play();
            setPlay(true);
        }

    };

    return (
        <div className="w-full h-[80%]">

            <ReactHlsPlayer
                playerRef={playerRef}
                src={url}
                playsInline
                disablePictureInPicture={true}
                preload="metadata"
                className="w-full h-full object-cover border mt-2 rounded-lg"
                onEnded={() => setPlay(false)}
            />

            <div
                className={cn(`
                    p-1.5 hover:bg-gray-300/40
                    transition-all duration-300 cursor-pointer rounded-md
                    absolute top-[50%] left-[45%]
                `)}
                onClick={() => onPlay()}
            >

                {play ?
                    <FaPause className="w-6 h-6 text-white" /> :
                    <FaPlay className="w-6 h-6 text-white" />
                }

            </div>

        </div>
    );
}