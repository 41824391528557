import { useIsPreview } from "@hooks";
import { TextTestimonial } from "./text/text";
import { useCommonStore, useFormStore } from "@store";
import { VideoTestimonial } from "./video/main.video";
import { AudioTestimonial } from "./audio/audio.main";

export function PreviewCollectionScreen() {

    const isPreview = useIsPreview();

    const allowedTestimonialTypes = useFormStore((store) => store.welcome.allowedTestimonialTypes);
    const previewTestimonialType = useCommonStore((store) => store.previewTestimonialType);

    const collectVideo = allowedTestimonialTypes.includes("video");
    const collectText = allowedTestimonialTypes.includes("text");
    const collectAudio = allowedTestimonialTypes.includes("audio");

    if (isPreview) {
        return (
            <div>

                {previewTestimonialType === "video" ? <VideoTestimonial /> : null}

                {previewTestimonialType === "text" ? <TextTestimonial /> : null}

                {previewTestimonialType === "audio" ? <AudioTestimonial /> : null}

            </div>
        );
    }

    return (
        <div>

            {collectVideo ? <VideoTestimonial /> : null}

            {collectAudio ? <AudioTestimonial /> : null}

            {collectText ? <TextTestimonial /> : null}

        </div>
    );
}