import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../keys";


const State: Testimonial.State = {
    landedAt: "",
    id: "",
    metaData: {
        country: null,
        ip: null,
        origin: null,
        userAgent: null
    },
    outcome: "",
    reward: null,
    startedAt: "",
    submittedAt: "",
    type: "",
    testimonial: {
        rating: 0,
        audio: null,
        video: null,
        text: null
    },
    hiddenFields: {},
    customerDetails: {},
    customConsentAccepted: true
};


export const useTestimonialStore = create<Testimonial.Type>()(devtools(persist(immer((set => ({
    ...State,
    set: (params) => set((state) => {

        return { ...state, ...params };

    },
        false,
        "testimonial/set"
    ),
    setId: (id) => set((state) => {

        state.id = id;

    },
        false,
        "testimonial/setId"
    ),
    setCustomDetails: (params) => set((state) => {

        state.customerDetails[params.id] = {
            answer: params.answer as any,
            type: params.type as any,
            varType: params.varType as any
        };

    },
        false,
        "testimonial/setCustomDetails"
    ),
    setType: (type) => set((state) => { state.type = type; },
        false,
        "testimonial/setType"
    ),
    setHiddenFields: (fields) => set((state) => { state.hiddenFields = fields; },
        false,
        "testimonial/setHiddenFields"
    ),
    setMetaData: (params) => set((state) => { state.metaData = { ...state.metaData, ...params }; },
        false,
        "testimonial/setMetaData"
    ),
    setOutcome: (outcome) => set((state) => { state.outcome = outcome; },
        false,
        "testimonial/setOutcome"
    ),
    setReward: (reward) => set((state) => { state.reward = reward; },
        false,
        "testimonial/setReward"
    ),
    setStartedAt: (startedAt) => set((state) => { state.startedAt = startedAt; },
        false,
        "testimonial/setStartedAt"
    ),
    setCustomConsent: (consent) => set((state) => { state.customConsentAccepted = consent; },
        false,
        "testimonial/setCustomConsent"
    ),
    setSubmittedAt: (submittedAt) => set((state) => { state.submittedAt = submittedAt; },
        false,
        "testimonial/setSubmittedAt"
    ),
    setLandedAt: (landedAt) => set((state) => { state.landedAt = landedAt; },
        false,
        "testimonial/setLandedAt"
    ),
    /* testimonial */
    setAudioTestimonial: (param) => set((state) => {

        if (param === null) {

            state.testimonial.audio = null;

        } else {

            state.testimonial.audio = {
                duration: param?.duration ?? 0,
                format: param?.format ?? "",
                size: param?.size ?? 0,
                imageAttachments: param?.imageAttachments ?? []
            };

            state.testimonial.video = null;
            state.testimonial.text = null;

        }

    },
        false,
        "testimonial/setAudioTestimonial"
    ),
    setVideoTestimonial: (params) => set((state) => {

        if (params === null) {

            state.testimonial.video = null;

        } else {

            state.testimonial.video = {
                duration: params?.duration ?? 0,
                format: params?.format ?? '',
                size: params?.size ?? ""
            };

            state.testimonial.audio = null;
            state.testimonial.text = null;

        }

    },
        false,
        "testimonial/setVideoTestimonial"
    ),
    setTextTestimonial: (param) => set((state) => {

        if (param === null) {

            state.testimonial.text = null;

        } else {

            state.testimonial.text = {
                content: param?.content ?? "",
                imageAttachments: param?.imageAttachments ?? []
            };

            state.testimonial.video = null;
            state.testimonial.audio = null;

        }

    },
        false,
        "testimonial/setTextTestimonialContent"
    ),
    setRating: (rating) => set((state) => {

        state.testimonial.rating = rating;

    },
        false,
        "testimonial/setRating"
    ),
    clear: () => set((state) => {

        state.id = "";
        state.customConsentAccepted = false;
        state.customerDetails = {};
        state.hiddenFields = {};
        state.landedAt = "";
        state.metaData = {
            country: "",
            ip: "",
            origin: "",
            userAgent: ""
        };
        state.outcome = "";
        state.reward = null;
        state.startedAt = "";
        state.submittedAt = "";
        state.testimonial = {
            audio: {
                duration: 0,
                format: "",
                size: 0,
                imageAttachments: []
            },
            rating: 0,
            text: {
                content: "",
                imageAttachments: []
            },
            video: {
                duration: 0,
                format: "",
                size: 0
            }
        };
        state.type = "text";

    },
        false,
        "useTestimonialStore/clear"
    )
}))), {
    name: StoreKeys.testimonial
}), {
    name: StoreKeys.testimonial,
    enabled: import.meta.env.DEV
}));