import { useFormDesign, useIsPreview } from "@hooks";
import { useCommonStore, useFormStore } from "@store";
import { cn } from "@utils/cn";
import { PurifyHTML } from "@utils/purifyHTML";
import { GiFullPizza } from "react-icons/gi";


export default function WelcomeRewardCard() {

    const isPreview = useIsPreview();

    const { welcomeMessage, wheelColor, fontFamily } = useFormStore((store) => ({
        welcomeMessage: store.reward.message,
        wheelColor: store.reward.color1,
        fontFamily: store.design.fontFamily
    }));

    const previewType = useCommonStore((store) => store.previewType);

    const { cardColor, fontColor, enableRTL } = useFormDesign();

    return (
        <div
            className={cn(`
                sm:w-[90%] md:w-[40%] h-auto bg-white rounded-lg shadow-lg border border-transparent 
                space-y-4  overflow-hidden p-2 flex items-center mb-10 relative
            `, {
                "sm:w-[80%]": isPreview && previewType === "laptop",
                "flex-row-reverse": enableRTL
            })}
            style={{
                background: cardColor
            }}
        >

            <p
                className="p-2 w-[80%]"
                dangerouslySetInnerHTML={{ __html: PurifyHTML(welcomeMessage) }}
                style={{
                    fontFamily,
                    color: fontColor
                }}
            />

            <GiFullPizza
                className={cn("w-[150px] h-[150px] absolute text-blue-800", {
                    "left-0 -translate-x-1/4": enableRTL,
                    "right-0 translate-x-1/4": enableRTL === false
                })}
                style={{
                    color: wheelColor
                }}
            />

        </div>
    );
}