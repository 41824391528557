import { useFormDesign } from "@hooks";
import { cn } from "@utils/cn";
import { useState } from "react";
import { MdCheck } from "react-icons/md";

type Props = {
    disable?: boolean,
    className?: string,
    defaultChecked?: boolean,
    onClick: () => void;
};

export function CheckBox({ disable, className, defaultChecked, onClick }: Props) {

    const [checked, setChecked] = useState(defaultChecked ?? false);

    const { primaryColor } = useFormDesign();

    const onClickHandler = () => {

        if (disable) return;

        setChecked(!checked);
        onClick();
    };

    return (
        <div
            className={cn(`
                h-[18px] w-[18px] flex items-center justify-center rounded bg-white border border-gray-400
                transition-all duration-100 ease-in-out cursor-pointer
            `, {
                "outline outline-2 outline-offset-1 outline-blue-400 bg-primary ": checked,
                "cursor-not-allowed": disable,
                '': false
            }, className)}
            onClick={() => onClickHandler()}
            style={{
                background: checked ? primaryColor : "white",
                outlineColor: checked ? `${primaryColor}40` : ''
            }}
        >

            {checked ? <MdCheck className="text-white" /> : null}

        </div>
    );
}