import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { FaPause, FaPlay } from "react-icons/fa6";

type Props = {
    profilePic?: string,
    type: WolType.Type["type"],
    videoUrl: string | null;
};

export function Avatar({ profilePic, type, videoUrl }: Props) {

    const playerRef = useRef<any>(null);

    const [play, setPlay] = useState(false);

    useEffect(() => {

        if (playerRef.current) {
            playerRef.current.play();
        }

    }, []);

    const onPlay = () => {

        if (play) {
            playerRef.current?.pause();
            setPlay(false);
        } else {
            playerRef.current?.play();
            setPlay(true);
        }

    };

    if (!videoUrl) <></>;

    return (
        <div className="border border-gray-300 shadow-md rounded-xl w-[180px] h-[200px]">

            {type !== "video" ?
                <img
                    src={profilePic}
                    className="w-full h-full"
                /> : null
            }

            {type === "video" ?
                <div className="flex items-center justify-center w-full h-full relative rounded-xl">

                    <div
                        className={cn(`
                            p-1.5 hover:bg-gray-300/40 border border-gray-200 inline-block
                            transition-all duration-300 cursor-pointer rounded-md absolute top-[40%]
                        `)}
                        onClick={() => onPlay()}
                    >

                        {play ?
                            <FaPause className="w-6 h-6 text-white" /> :
                            <FaPlay className="w-6 h-6 text-white" />
                        }

                    </div>

                    <ReactHlsPlayer
                        playerRef={playerRef}
                        src={videoUrl as string}
                        className="w-full h-full bg-gray-300 rounded-xl"
                    />

                </div> : null
            }

        </div>
    );
}