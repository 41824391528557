import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> { }

export function G2GroundIcon(props: Props) {
    return (
        <svg
            {...props}
            height="2500"
            viewBox="-36.72 -19.24 748.01 780.17"
            width="2454"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="#ff492c">
                <path d="m501.22 521c26.9 46.68 53.5 92.83 80.08 138.93-117.7 90.11-300.82 101-436.38-2.77-156-119.51-181.64-323.43-98.12-470.22 96.06-168.84 275.82-206.18 391.56-178.8-3.13 6.8-72.45 150.61-72.45 150.61s-5.48.36-8.58.42c-34.21 1.45-59.69 9.41-87 23.53a206.93 206.93 0 0 0 -109.84 159.76 201.71 201.71 0 0 0 13.68 100.29c11 26.82 26.56 50.64 47.42 70.75 32 30.88 70.08 50 114.22 56.33 41.8 6 82 .06 119.67-18.87 14.13-7.09 26.15-14.92 40.2-25.66 1.79-1.16 3.38-2.63 5.54-4.3z" />
                <path d="m501.48 111.61c-6.83-6.72-13.16-12.92-19.46-19.16-3.76-3.72-7.38-7.59-11.23-11.22-1.38-1.31-3-3.1-3-3.1s1.31-2.78 1.87-3.92c7.37-14.79 18.92-25.6 32.62-34.2a90.65 90.65 0 0 1 50.72-14.01c22.93.45 44.25 6.16 62.24 21.54 13.28 11.35 20.09 25.75 21.29 42.94 2 29-10 51.21-33.83 66.71-14 9.12-29.1 16.17-44.24 24.52-8.35 4.61-15.49 8.66-23.65 17-7.18 8.37-7.53 16.26-7.53 16.26l108.47-.14v48.31h-167.43v-4.67c-.64-23.74 2.13-46.08 13-67.64 10-19.78 25.54-34.26 44.21-45.41 14.38-8.59 29.52-15.9 43.93-24.45 8.89-5.27 15.17-13 15.12-24.21 0-9.62-7-18.17-17-20.84-23.58-6.36-47.58 3.79-60.06 25.37-1.82 3.15-3.68 6.28-6.04 10.32zm209.81 358.04-91.41-157.86h-180.89l-92 159.49h182.22l89.92 157.11z" /></g>
        </svg>
    );
}



