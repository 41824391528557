import { useIsPreview } from "@hooks";
import { Fragment } from "react";
import { cn } from "@utils/cn";
import { MediaRecodingState } from "./main.video";
import { useFormStore } from "@store";
import { BsCardImage } from "react-icons/bs";

type Props = {
    recordingState: MediaRecodingState,
    startingTimer: number,
    videoRef: React.RefObject<HTMLVideoElement>,
};

export function VideoPreview({ recordingState, startingTimer, videoRef }: Props) {

    const isPreview = useIsPreview();
    const fontFamily = useFormStore((store) => store.design.fontFamily);

    return (
        <Fragment>

            {isPreview ?
                <BsCardImage className="w-full h-[300px] text-white" /> :
                <video
                    autoPlay
                    id="video-preview"
                    ref={videoRef}
                    muted
                    style={{
                        transform: "scaleX(-1)"
                    }}
                    className={cn(`rounded-xl  object-cover w-full h-[300px]`, {
                        "blur-sm": recordingState === "starting"
                    })}
                />
            }

            {recordingState === "starting" ?
                <div
                    className={cn(`
                        absolute top-0 left-0 w-full h-full z-30 
                        flex flex-col items-center justify-center
                    `)}
                    style={{ fontFamily }}
                >

                    <h1 className="text-7xl font-semibold text-white">
                        {startingTimer}
                    </h1>

                    <p className="text-white font-medium">
                        <i className="mx-1">
                            Smile for the camera
                        </i>
                        🙂
                    </p>

                </div> : null
            }

        </Fragment>
    );
}