import { useFormDesign } from "@hooks";
import { cn } from "@utils/cn";
import { InputHTMLAttributes, forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    required?: boolean,
    label?: string,
    error?: string | null,
    register?: UseFormRegisterReturn<any>,
    mainClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, Props>(({
    required, className, register,
    error = "", mainClassName = "", label = "",
    ...props
}, ref) => {

    const { fontColor } = useFormDesign();

    return (
        <div className={cn("flex flex-col items-start justify-center", mainClassName)}>

            {label ?
                <label
                    className="text-sm font-medium text-gray-700 mx-1 mb-1"
                    style={{
                        color: `${fontColor}70`
                    }}
                >
                    {label}
                    {required ? <span className="text-red-500 font-medium"> * </span> : null}
                </label> : null
            }

            <input
                {...props}
                {...register}
                ref={ref}
                className={cn(`
                    flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 text-sm py-2 placeholder:text-gray-400 
                    focus:outline-none focus:ring-1 focus:ring-blue-400 focus:ring-offset-1
                    disabled:cursor-not-allowed disabled:opacity-50
                `, className, {
                    "focus:ring-red-400 right-1 ring-red-400 ring-offset-1 border-red-300": error ? true : false
                })}
                style={{
                    borderColor: `${fontColor}30`,
                }}
            />

            {error ?
                <span
                    className='text-red-500 flex flex-row items-center space-x-1 mt-1 px-1 text-sm font-normal text-left'
                >

                    * {error}

                </span > : null
            }

        </div>
    );
});
