import { useSearchParams } from "react-router-dom";
import MeshGradient from "mesh-gradient.js";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { TestimonialCard } from "./card/main.card";

export function WallOfLoveCardPreviewPage() {

    const [searchParams] = useSearchParams();

    const COLORS = [
        "#eb75b6",
        "#ddf3ff",
        "#6e3deb",
        "#c92f3c"
    ];

    const gradient = new MeshGradient();

    useEffect(() => {

        gradient.initGradient("#canvas", COLORS);

        gradient?.changePosition(500);

    }, []);

    const name = searchParams.get("name") ?? "";
    const source = searchParams.get("source") ?? "";
    const rating = parseInt(searchParams.get("rating") ?? "0");
    const text = searchParams.get("text") ?? "";
    const avatar = searchParams.get("avatar") ?? "";
    const sourceLink = searchParams.get("sourceLink") ?? "";
    // const submittedAt = searchParams.get("submittedAt") ?? "";
    // const attachments = searchParams.get("attachments") ?? "";

    console.log({ name, rating, source, text, avatar });

    const testimonial: WolType.Type = {
        id: uuid(),
        type: "text",
        source: (source as any),
        customerDetails: {
            "name": {
                answer: "test",
                type: "",
                varType: ""
            }
        },
        testimonial: {
            rating,
            text: {
                content: text,
                imageAttachments: []
            },
            video: null,
            audio: null
        },
        sourceLink,
        submittedAt: new Date().toISOString()
    };

    return (
        <div className="w-screen h-screen overflow-hidden flex items-center justify-center">

            <canvas
                id="canvas"
                className="absolute top-0 left-0 w-full h-full"
            />

            <div
                className="w-[40%] h-auto"
            >

                <TestimonialCard {...testimonial} />

            </div>


        </div>
    );
}