import { useCustomLabels } from "@hooks";
import { useFormStore } from "@store";
import { useMemo } from "react";
import { ZodTypeAny, z } from "zod";

export function useSchema() {

    const labels = useCustomLabels();

    const schema = useMemo(() => {

        const schema = z.object({});
        const shape = schema.shape as Record<string, ZodTypeAny>;

        Object.entries(useFormStore.getState().customDetails.fields).forEach(field => {

            const [key, setting] = field;

            const { collect } = setting;

            if (key === "name" && collect) {

                shape[key] = z.string().min(1, labels.nameFieldRequired);

            } else if (key === "company" && collect) {

                shape[key] = z.string().min(1, labels.companyFieldRequired);

            } else if (key === "email" && collect) {

                shape[key] = z.string().min(1, labels.emailFieldRequired).email(labels.emailField);

            } else if (key === "role" && collect) {

                shape[key] = z.string().min(3, labels.roleFieldRequired);

            } else if (key === "website" && collect) {

                shape[key] = z.string().min(1, labels.websiteFieldRequired).url(labels.websiteField);

            }

        });


        useFormStore.getState().customDetails.customFields.forEach(field => {

            const { id, type, required } = field;

            if (type === "email") {

                shape[id] = z.string()
                    .min(required ? 1 : 0, labels.custom_EmailFieldRequired)
                    .email(labels.custom_EmailField);

            } else if (type === "url") {

                shape[id] = z.string()
                    .min(required ? 1 : 0, labels.custom_UrlFieldRequired)
                    .url(labels.custom_UrlField);

            } else if (type === "text") {

                shape[id] = z.string().min(required ? 1 : 0, labels.custom_TextField);

            } else if (type === "longText") {

                shape[id] = z.string().min(required ? 1 : 0, labels.custom_LongTextField);

            }

        });

        return schema;

    }, []);

    return schema;

}