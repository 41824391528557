import { ParseAxiosError } from "@lib/axios";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";


export function useAnalyticsApiClient() {

    const update = useMutation({
        mutationKey: [QueryKeys.analytics.update],
        mutationFn: async (params: { id: string, event: "view" | "started"; }) => {
            try {

                return (await axios.post("/api/v1/analytics/event", {}, {
                    params: {
                        id: params.id,
                        event: params.event
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return { update };

}