import { useEffect, useRef } from "react";
import { Config } from "@config";
import { QueryKeys, useGetQueryData } from "@hooks";
import ReactHlsPlayer from "react-hls-player";

export function VideoTestimonial() {

    const query = useGetQueryData<Api.TestimonialClient.get>([QueryKeys.testimonial.get]);

    const video = query.testimonial.testimonial.video;

    const playerRef = useRef<any>(null);

    const videoUrl = Config.cdn() + video?.url;

    useEffect(() => {

        if (playerRef.current) {
            playerRef.current.play();
        }

    }, []);

    return (
        <ReactHlsPlayer
            playerRef={playerRef}
            src={videoUrl}
            autoPlay
            controls
            className="h-[280px] w-full m-auto rounded-md mt-4 outline-none border border-gray-200"
        />
    );
}