import { useFormDesign, useIsPreview } from "@hooks";
import { cn } from "@utils/cn";
import { SideBar } from "./sideBar";
import { useEffect, } from "react";
import { SpinWheel } from "./spinWheel/wheel.main";
import { useCommonStore, useFormStore } from "@store";


export function Reward() {

    const { submitted, wheelSpun } = useCommonStore((store) => ({
        submitted: store.submitted,
        wheelSpun: store.spinWheelSpun
    }));

    const allow = useFormStore((store) => store.reward.allow);

    const { enableRTL } = useFormDesign();

    const isPreview = useIsPreview();

    useEffect(() => {

        /* 
            TODO 

            check weather spin wheel has spun and set current set to welcome seen
        */

        if (submitted && wheelSpun) {
            localStorage.clear();
            useCommonStore.getState().clear();
            useCommonStore.getState().setCurrentStep("welcome");
            return;
        }

        // if (submitted && wheelSpun === false && )

        if (!allow) {
            useCommonStore.getState().setCurrentStep("welcome");
            return;
        }


    }, []);

    return (
        <div
            className={cn(`
                flex md:flex-row items-center justify-center w-screen h-screen bg-gray-200 dotted-bg
                xs:flex-col xs:overflow-auto
            `, {
                "justify-start items-start": isPreview,
            })}
            dir={enableRTL ? "rtl" : "ltr"}
        >

            <SideBar />

            {isPreview === false ?
                <div className="md:w-[60%] xs:w-full xs:py-6 h-full flex items-center justify-center">

                    <SpinWheel />

                </div> : null
            }

        </div>
    );
}

export default Reward;