import { AxiosError } from "axios";

type ApiError = AxiosError<{
    statusCode: number,
    message: string | string[],
    error: string;
}>;

export function ParseAxiosError(error: ApiError) {

    if (typeof error === "object" && error.response?.data.message) {
        return error.response.data;
    }

    return error.message;

}