import { Button } from "@components/button";
import { useTestimonialApiClient, } from "@hooks";
import { useParams } from "react-router-dom";
import { TestimonialCard } from "./card/main.card";
import Masonry from 'react-layout-masonry';
import { VideoTestimonial } from "./card/video";
import { Config } from "@config";
import { useEffect, useState } from "react";


export function WallOfLovePage() {

    const { id } = useParams() as { id: string; };

    const { getWallOfLoveTestimonials } = useTestimonialApiClient({});

    const [testimonials, setTestimonials] = useState<any[]>([]);

    useEffect(() => { onLoad(); }, []);

    const onLoad = async () => {

        const data = await getWallOfLoveTestimonials.mutateAsync(id);

        setTestimonials([...data.testimonials]);

    };

    return (
        <div className="w-screen h-screen overflow-x-hidden bg-gray-100">

            <div className="bg-primary h-[60%] flex flex-col items-center justify-center relative">

                {/* <canvas
                    id="canvas"
                    className="absolute top-0 left-0 w-full h-full"
                /> */}

                <div className="w-auto absolute top-5 left-5 bg-white rounded-lg z-[10]">

                    <img
                        src={Config.misc.placeHolderLogo}
                        className="w-[50px] h-[50px] p-1 shadow-lg"
                    />

                </div>

                <div className="flex flex-col items-center justify-center space-y-4 pt-5 z-[10]">

                    <p className="text-6xl font-semibold tracking-wide text-white">
                        Wall of Love
                    </p>

                    <p className="text-sm font-normal tracking-wide text-white my-2">
                        See what customers are saying about us
                    </p>

                    <Button
                        className="bg-white rounded-full inline w-auto text-black"
                        onClick={() => { }}
                    >
                        Visit our website
                    </Button>

                </div>

            </div>


            <div className="flex items-center justify-center w-[95%] p-6 m-auto translate-y-[-80px] ">

                <Masonry
                    columns={{
                        1280: 3,
                        840: 2,
                        650: 1,
                    }}
                    gap={20}
                >

                    {testimonials.map((testimonial, idx) => {
                        console.log({ testimonial });
                        return testimonial.type !== "video" ?
                            <TestimonialCard key={idx} {...testimonial} /> :
                            <VideoTestimonial key={idx} {...testimonial} />;

                    })}

                </Masonry>

            </div>


            {/* 
            {isNotLoading ?
                <div
                    className={cn("p-6 h-full w-[95%] translate-y-[-80px] ")}
                    style={{}}
                >

                    {testimonials.map((testimonial, idx) => <TestimonialCard key={idx} {...testimonial} />)}

                </div> :
                <div className="border border-red-600 w-full h-[40%]">

                    <Loading text="loading..." />

                </div>
            } */}

        </div>
    );
}